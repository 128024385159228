<script>
  import Vue from 'vue';
  import { Bar } from 'vue-chartjs'
  export default Vue.extend({
    extends: Bar,
    methods: {
    },
    data() {
      return {
        chartType: 'bar',
        chartData: {
              labels : [ this.$t('overview.prevPeriod'), this.$t('overview.currPeriod') ],
              datasets: [
                {
                  label: this.$t('overview.ipad'),
                  data: [0,0],
                  backgroundColor: "#5175C3",
                  borderColor: "#5175C3",
                  borderWidth: 3,
                  fill: false,
                  tension: 0,
                  pointRadius: 0,
                  pointHitRadius: 15,
                  barPercentage: 0.3,
                  minBarLength: 1,
                },
                {
                  label: this.$t('overview.iphone'),
                  data: [0,0],
                  backgroundColor: "#DD6742",
                  borderColor: "#DD6742",
                  borderWidth: 3,
                  fill: false,
                  tension: 0,
                  pointRadius: 0,
                  pointHitRadius: 15,
                  barPercentage: 0.3,
                  minBarLength: 1,
                },
                {
                  label: this.$t('overview.unknown'),
                  data: [0,0],
                  backgroundColor: "#51818F",
                  borderColor: "#51818F",
                  borderWidth: 3,
                  fill: false,
                  tension: 0,
                  pointRadius: 0,
                  pointHitRadius: 15,
                  barPercentage: 0.3,
                  minBarLength: 1,
                }
              ],
        },
        chartOptions: {
              maintainAspectRatio: false,
              layout: {
                  padding: {
                      left: 0,
                      right: 0,
                      top: 30,
                      bottom: 0
                  }
              },
              legend: {
                  display: true,
                  position: 'bottom',
                  labels: {
                      fontColor: 'rgb(0, 0, 0)'
                  }
              },
              responsive: true,
              lineTension: 0,
              scales: {
                xAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: '',
                  },
                  gridLines: {
                      drawOnChartArea: false
                  },
                }],
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      padding: 25
                    },
                    gridLines: {
                      drawOnChartArea: false
                    }
                  }
                ]
              },
              tooltips: {
                enabled: true
              },
              hover: {
                animationDuration: 1
              },
              animation: {
                duration: 1,
                onComplete: function () {
                  var chartInstance = this.chart,
                  ctx = chartInstance.ctx;
                  ctx.textAlign = 'center';
                  ctx.fillStyle = "rgba(0, 0, 0, 1)";
                  ctx.textBaseline = 'bottom';
                  this.data.datasets.forEach(function (dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                      var data = dataset.data[index];
                      ctx.fillText(data, bar._model.x, bar._model.y - 5);
                    });
                  });
                }
              }
            },
      }
    },
    props:{
      deviceData:Object,
    },
    mounted(){
      this.renderChart(this.chartData, this.chartOptions)
    },
    watch: {
      deviceData: function(deviceData) {
        const data = deviceData.data;
        const mp = parseFloat(data.mp.toFixed(2));
        const mc = parseFloat(data.mc.toFixed(2));
        const fp = parseFloat(data.fp.toFixed(2));
        const fc = parseFloat(data.fc.toFixed(2));
        const up = parseFloat(data.up.toFixed(2));
        const uc = parseFloat(data.uc.toFixed(2));
        this.chartData.datasets[0].data = [mp, mc];
        this.chartData.datasets[1].data = [fp, fc];
        this.chartData.datasets[2].data = [up, uc];
        this.renderChart(this.chartData, this.chartOptions);
      }

    }
  });

</script>
