
















































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue'
import { GET_QUEUED_TASKS, DELETE_KBBU } from '../utils/apollo-queries'
import { time } from 'console';
export default Vue.extend({
  name: 'TaskDialog',

  data() {
    return {
      headers: [
        {
          text: 'Category Name',
          align: 'start',
          sortable: false,
          value: 'category',
        },
        { text: 'Date Last Queued', value: 'lastQueuedAt' },
        { text: 'Count Remain', value: 'countRemain', align: 'center', },
      ],
      allTasks: [],
      catTasks: [],
      currCatId : 0,
      showTasks: false,
      tasks: [],
      open: false,
      openDeleteConfirmation: false,
      taskToDelete: null,
    }
  },
  props: {
    openDR: {
      type: Object,
      required: false,
    },
  },
  watch: {
    openDR: function (data) {
      this.open = true
      this.showTasks = false
      this.categoryId = 0
      this.allTasks = [],
      this.catTasks = [],
      this.tasks = [],
      this.getQueuedTasks()
    }
  },
  methods: {
    cancelDelete() {
    this.openDeleteConfirmation = false;
    this.taskToDelete = null;
  },
  confirmKBBU(taskId) {
    this.taskToDelete = taskId;
    this.openDeleteConfirmation = true;  
  },
    showCategoryTasks(categoryId){      
      this.catTasks = this.allTasks.filter(t => t.categoryId == categoryId)
      if(this.showTasks){
        if(this.currCatId == categoryId){
          this.showTasks = false
        }else{
          this.currCatId = categoryId
          this.allTasks.map(t => t.showMsgs = false)
          this.catTasks.map(t => t.showMsgs = false)
        }
      }else{
        if(this.currCatId == categoryId){
          this.showTasks = true
        }else{
          this.showTasks = true
          this.currCatId = categoryId
          this.allTasks.map(t => t.showMsgs = false)
          this.catTasks.map(t => t.showMsgs = false)
        }
      }      
    },
    toggleShowMsgs(idx){
      if(this.catTasks[idx].showMsgs) this.catTasks[idx].showMsgs = false
      else this.catTasks[idx].showMsgs = true
    },
    getQueuedTasks() {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: GET_QUEUED_TASKS,
          variables: {
            at: Date.now(),
          },
        })
        .then((res) => {
          if (res.data.getQueuedTasks) {
            const items = res.data.getQueuedTasks || [];
            this.allTasks = res.data.getQueuedTasks || [];
            this.allTasks = this.allTasks.map(t => {
              const cr = (t.totalCount - (t.successCount + t.failedCount + t.errorCount))
              return {
                ...t,
                showMsgs: false,
                cr: cr < 0 ? 0 : cr,
                progress: cr < 0 ? 100 : parseInt(((t.successCount + t.failedCount + t.errorCount)/t.totalCount)*100)
              }
            })
            const catIds = []
            this.tasks = []
            for (let index = 0; index < items.length; index++) {
              const element = items[index];              
              if(catIds.includes(element.categoryId)) {
                const idx = this.tasks.findIndex(t => t.categoryId === element.categoryId)
                this.tasks[idx].totalCount = this.tasks[idx].totalCount + element.totalCount
                this.tasks[idx].successCount = this.tasks[idx].successCount + element.successCount
                this.tasks[idx].failedCount = this.tasks[idx].failedCount + element.failedCount
                this.tasks[idx].errorCount = this.tasks[idx].errorCount + element.errorCount
                this.tasks[idx].lastQueuedAt = (element.lastQueuedAt > this.tasks[idx].lastQueuedAt) ? element.lastQueuedAt : this.tasks[idx].lastQueuedAt                
                const cr = (element.totalCount - (element.successCount + element.failedCount + element.errorCount))
                this.tasks[idx].cr = this.tasks[idx].cr + (cr < 0 ? 0 : cr)
              }else{
                catIds.push(element.categoryId)
                const cr = (element.totalCount - (element.successCount + element.failedCount + element.errorCount))
                this.tasks.push({
                  categoryId : element.categoryId,
                  category : element.category,
                  totalCount : element.totalCount,
                  successCount : element.successCount,
                  failedCount : element.failedCount,
                  lastQueuedAt : element.lastQueuedAt,
                  errorCount: element.errorCount,
                  cr: cr < 0 ? 0 : cr
                })
              }
            }
            this.fullscreenLoader.hide();
          }
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
        });
    },
    deleteKBBU(taskId) {
      this.taskToDelete = null;
      this.openDeleteConfirmation = false;
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: DELETE_KBBU,
          variables: {
            taskId: taskId,
          },
        })
        .then((res) => {
          this.fullscreenLoader.hide();
          const data = res.data.deleteQueuedTask
          if(data.successMessage === "OK"){            
            this.open = true
            this.showTasks = false
            this.categoryId = 0
            this.allTasks = [],
            this.catTasks = [],
            this.tasks = [],
            this.getQueuedTasks()
            this.$notify.success('Task deleted successfully');
          }else{
            this.$notify.error('Unable to delete task');
          }
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
          this.$notify.error('Unable to delete task');
        });
    },
  }
});
