var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-app",
        [
          _c(
            "div",
            { staticClass: "inner-container" },
            [
              _c(
                "div",
                {
                  staticClass: "top-page-header page-header-with-banner",
                  attrs: { lang: _vm.locale },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "select-area mt-1 d-flex align-center" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-2",
                          attrs: {
                            cols: "12",
                            xl: "2",
                            lg: "2",
                            md: "4",
                            sm: "4",
                          },
                        },
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass: "color-select-icon with-small",
                              attrs: { color: "white", elevation: "0" },
                            },
                            [
                              _c("v-autocomplete", {
                                staticClass: "rounded-lg",
                                attrs: {
                                  items: _vm.searchedApps,
                                  loading: _vm.loadingApps,
                                  "item-text": "appName",
                                  "item-value": "adamId",
                                  required: "",
                                  outlined: "",
                                  dense: "",
                                  "prepend-inner-icon": "mdi-cellphone",
                                  "return-object": "",
                                  disabled: _vm.searchedApps.length < 2,
                                },
                                on: { change: _vm.changeApp },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        [
                                          _c("v-img", {
                                            staticClass: "rounded-lg",
                                            attrs: {
                                              color: "secondary",
                                              "lazy-src": data.item.appIcon,
                                              "max-width": "30",
                                              "max-height": "30",
                                              "min-width": "20",
                                              "min-height": "20",
                                              src: data.item.appIcon,
                                            },
                                          }),
                                          _c(
                                            "v-list-item-content",
                                            { staticClass: "ml-2" },
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.item.appName) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.searchApp,
                                  callback: function ($$v) {
                                    _vm.searchApp = $$v
                                  },
                                  expression: "searchApp",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "py-2 mb-2",
                          attrs: {
                            cols: "12",
                            xl: "2",
                            lg: "2",
                            md: "4",
                            sm: "4",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-center justify-end flex-wrap w-100 overview-date-picker",
                            },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "menu",
                                  staticClass: "overview-menu date-picker",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "return-value": _vm.rangePicker,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "min-width": "auto",
                                    right: "",
                                  },
                                  on: {
                                    "update:returnValue": function ($event) {
                                      _vm.rangePicker = $event
                                    },
                                    "update:return-value": function ($event) {
                                      _vm.rangePicker = $event
                                    },
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "v-date-picker-cs w-sm-100",
                                                  staticStyle: {
                                                    "font-size": "revert",
                                                  },
                                                  attrs: {
                                                    "prepend-icon":
                                                      "mdi-calendar",
                                                    readonly: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.filterDateRangeText,
                                                    callback: function ($$v) {
                                                      _vm.filterDateRangeText = $$v
                                                    },
                                                    expression:
                                                      "filterDateRangeText",
                                                  },
                                                },
                                                "v-text-field",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.rangePicker,
                                    callback: function ($$v) {
                                      _vm.rangePicker = $$v
                                    },
                                    expression: "rangePicker",
                                  },
                                },
                                [
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { flex: "auto" } },
                                          [
                                            _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "flex-container column mt-3",
                                                staticStyle: {
                                                  "list-style-type": "none",
                                                },
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  { staticClass: "flex-item" },
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        staticClass: "ma-1",
                                                        attrs: { label: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.selectPreset(
                                                              "TODAY"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "dateRangePresets.today"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "flex-item" },
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        staticClass: "ma-1",
                                                        attrs: { label: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.selectPreset(
                                                              "YESTERDAY"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "dateRangePresets.yesterday"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "flex-item" },
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        staticClass: "ma-1",
                                                        attrs: { label: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.selectPreset(
                                                              "WEEKTODATE"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "dateRangePresets.weekToDate"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "flex-item" },
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        staticClass: "ma-1",
                                                        attrs: { label: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.selectPreset(
                                                              "LASTWEEK"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "dateRangePresets.lastWeek"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "flex-item" },
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        staticClass: "ma-1",
                                                        attrs: { label: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.selectPreset(
                                                              "LASTSEVENDAYS"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "dateRangePresets.lastSevenDays"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "flex-item" },
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        staticClass: "ma-1",
                                                        attrs: { label: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.selectPreset(
                                                              "MONTHTODATE"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "dateRangePresets.monthToDate"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "flex-item" },
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        staticClass: "ma-1",
                                                        attrs: { label: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.selectPreset(
                                                              "LASTMONTH"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "dateRangePresets.lastMonth"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "li",
                                                  { staticClass: "flex-item" },
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        staticClass: "ma-1",
                                                        attrs: { label: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.selectPreset(
                                                              "LASTTHIRTYDAY"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "dateRangePresets.lastThirtyDays"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticStyle: { flex: "auto" } },
                                          [
                                            _c(
                                              "v-date-picker",
                                              {
                                                attrs: {
                                                  max: _vm.maxDate,
                                                  "no-title": "",
                                                  scrollable: "",
                                                  range: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.filterDateRangeModel,
                                                  callback: function ($$v) {
                                                    _vm.filterDateRangeModel = $$v
                                                  },
                                                  expression:
                                                    "filterDateRangeModel",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      color: "grey",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.rangePicker = false
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Cancel")]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        _vm.filterDateRangeModel &&
                                                        _vm.filterDateRangeModel
                                                          .length !== 2,
                                                      text: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: _vm.changePeriod,
                                                    },
                                                  },
                                                  [_vm._v("OK")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "mb-2 py-2",
                          attrs: {
                            cols: "12",
                            xl: "2",
                            lg: "2",
                            md: "4",
                            sm: "4",
                          },
                        },
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass: "color-select-icon w-100",
                              attrs: { color: "white", elevation: "0" },
                            },
                            [
                              _vm.searchApp.adamId === 0
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        bottom: "",
                                        color: "primarydark",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    _vm._b(
                                                      { staticClass: "w-100" },
                                                      "div",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-autocomplete", {
                                                      staticClass:
                                                        "rounded-lg w-100",
                                                      attrs: {
                                                        items:
                                                          _vm.appGoals || [],
                                                        "item-text": "name",
                                                        "item-value": "_id",
                                                        required: "",
                                                        outlined: "",
                                                        dense: "",
                                                        "prepend-inner-icon":
                                                          "mdi-bullseye",
                                                        "return-object": "",
                                                        disabled:
                                                          _vm.disableGoalDD,
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.changePeriod,
                                                      },
                                                      model: {
                                                        value: _vm.selectedGoal,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.selectedGoal = $$v
                                                        },
                                                        expression:
                                                          "selectedGoal",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2549387983
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "overview.selectGoalFirstSelectApp"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                : _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        bottom: "",
                                        color: "primarydark",
                                        "open-delay": 3000,
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "div",
                                                _vm._g(
                                                  _vm._b(
                                                    {},
                                                    "div",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-autocomplete", {
                                                    staticClass: "rounded-lg",
                                                    attrs: {
                                                      items: _vm.appGoals || [],
                                                      "item-text": "name",
                                                      "item-value": "_id",
                                                      required: "",
                                                      outlined: "",
                                                      dense: "",
                                                      "prepend-inner-icon":
                                                        "mdi-bullseye",
                                                      "return-object": "",
                                                      disabled:
                                                        _vm.disableGoalDD,
                                                    },
                                                    on: {
                                                      change: _vm.changePeriod,
                                                    },
                                                    model: {
                                                      value: _vm.selectedGoal,
                                                      callback: function ($$v) {
                                                        _vm.selectedGoal = $$v
                                                      },
                                                      expression:
                                                        "selectedGoal",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [
                                      _vm.disableGoalDD
                                        ? _c("span", [
                                            _vm._v(
                                              "You do not have any goals created for this App"
                                            ),
                                          ])
                                        : _vm._e(),
                                      !_vm.disableGoalDD
                                        ? _c("span", [
                                            _vm._v(
                                              "Filter data by selecting a goal here"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "mb-2 d-flex py-2 flex-wrap justify-space-between w-100",
                          attrs: {
                            cols: "12",
                            xl: "6",
                            lg: "6",
                            md: "12",
                            sm: "12",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", color: "primarydark" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "br-8 px-6 h-4 br d-flex align-center justify-center white-bg kai-sync cursor-pointer ml-0 mr-1",
                                                  attrs: { color: " " },
                                                },
                                                "div",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "primary--text d-flex align-center justify-center flex-column font-13 mt-1 light-font font-600",
                                                  on: {
                                                    click: _vm.openDataRequests,
                                                  },
                                                },
                                                [
                                                  _c("v-progress-linear", {
                                                    staticClass: "mb-0 w-100",
                                                    attrs: {
                                                      value: _vm.drProgress,
                                                      color: "primary",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("pages.kar")
                                                      ) +
                                                      " - " +
                                                      _vm._s(
                                                        parseInt(_vm.drProgress)
                                                      ) +
                                                      "% "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [_c("span", [_vm._v("See your data requests")])]
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", color: "primarydark" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "br-8 px-6 h-4 br d-flex align-center justify-center white-bg kai-sync cursor-pointer ml-0",
                                                  attrs: { color: " " },
                                                },
                                                "div",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "primary--text d-flex justify-center flex-column font-13 mt-1 light-font font-600 w-100",
                                                  on: {
                                                    click: _vm.openTaskRequests,
                                                  },
                                                },
                                                [
                                                  _c("v-progress-linear", {
                                                    staticClass: "mb-0 w-100",
                                                    attrs: {
                                                      value: _vm.drProgress,
                                                      color: "primary",
                                                    },
                                                  }),
                                                  _vm._v(" Task - 0% "),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [_c("span", [_vm._v("Task")])]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "csv-logo d-flex align-center justify-end",
                              on: { click: _vm.showExportReportModel },
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-img",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "cursor-pointer",
                                                  attrs: {
                                                    src: require("../assets/images/csv.svg"),
                                                  },
                                                },
                                                "v-img",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("overview.exportCSV"))
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm.exportReportModel
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "export-csv",
                                attrs: { justify: "center" },
                              },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "export-csv-inner",
                                    attrs: { elevation: "0", attach: "" },
                                  },
                                  [
                                    _c(
                                      "v-date-picker",
                                      {
                                        staticClass: "w-100",
                                        attrs: {
                                          "no-title": "",
                                          scrollable: "",
                                          elevation: "2",
                                          range: "",
                                          max: _vm.getMaxDate(),
                                        },
                                        model: {
                                          value: _vm.filterDateRangeModel,
                                          callback: function ($$v) {
                                            _vm.filterDateRangeModel = $$v
                                          },
                                          expression: "filterDateRangeModel",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column pa-3 pr-7 w-100 export-heading",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-space-between align-center w-100",
                                              },
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "primary--text",
                                                  },
                                                  [_vm._v("Export CSV")]
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "primary" },
                                                    on: {
                                                      click:
                                                        _vm.closeExportReportModel,
                                                    },
                                                  },
                                                  [_vm._v("mdi-close")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("v-divider", {
                                              staticClass: "my-2 w-100",
                                              attrs: { horizontal: "" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column w-100 align-start justify-start pa-3",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c("v-select", {
                                                  key: _vm.exportReportKey,
                                                  staticClass:
                                                    "mt-1 w-100 mb-2",
                                                  attrs: {
                                                    items: _vm.reportDatasets,
                                                    placeholder:
                                                      "Report Datasets",
                                                    label: "Report Datasets",
                                                    "item-text": "text",
                                                    "return-object": "",
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    multiple: "",
                                                    "item-disabled": "disabled",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "selection",
                                                        fn: function (ref) {
                                                          var item = ref.item
                                                          var index = ref.index
                                                          return [
                                                            index === 0
                                                              ? _c("v-chip", [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.text
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ])
                                                              : _vm._e(),
                                                            index === 1
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "grey--text text-caption",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " (+" +
                                                                        _vm._s(
                                                                          _vm
                                                                            .selectedDatasets
                                                                            .length -
                                                                            1
                                                                        ) +
                                                                        " others) "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3398933626
                                                  ),
                                                  model: {
                                                    value: _vm.selectedDatasets,
                                                    callback: function ($$v) {
                                                      _vm.selectedDatasets = $$v
                                                    },
                                                    expression:
                                                      "selectedDatasets",
                                                  },
                                                }),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      "max-width": "300px",
                                                      color: "primarydark",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function (ref) {
                                                            var on = ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 greyxdark--text inside-select-icon",
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-help-circle-outline"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1803072569
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Datasets that are selected in the dropdown will be exported in report"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "sticky-label pl-2",
                                              },
                                              [_vm._v("Email address")]
                                            ),
                                            _c("v-text-field", {
                                              staticClass: "w-100",
                                              attrs: { dense: "" },
                                              model: {
                                                value: _vm.receiverEmail,
                                                callback: function ($$v) {
                                                  _vm.receiverEmail = $$v
                                                },
                                                expression: "receiverEmail",
                                              },
                                            }),
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "w-100 mt-4",
                                                attrs: { color: "primary" },
                                                on: { click: _vm.exportReport },
                                              },
                                              [_vm._v(" Send Report ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "v-container",
                {
                  staticClass: "v-main-content overview-top",
                  attrs: { fluid: "" },
                },
                [
                  [
                    _c(
                      "div",
                      {
                        staticClass: "colFullOverview",
                        class: { open: _vm.showCL },
                      },
                      [
                        _vm.existCL
                          ? _c(
                              "v-btn",
                              {
                                staticClass:
                                  "floatingBtn rounded-r-xl-0 rounded-l-xl",
                                attrs: {
                                  color: "primary ",
                                  elevation: "0",
                                  dark: "",
                                  absolute: "",
                                  top: "",
                                  right: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showCheckList()
                                  },
                                },
                              },
                              [
                                _c("v-icon", { staticClass: "first-ic" }, [
                                  _vm._v("mdi-check-circle"),
                                ]),
                                _c("v-icon", { staticClass: "second-ic" }, [
                                  _vm._v("mdi-close-circle"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "col-left-main" },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "h-100",
                                    attrs: {
                                      cols: "12",
                                      xl: "6",
                                      lg: "6",
                                      md: "12",
                                      sm: "12",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass:
                                          "v-fixed-top-section style-3",
                                        class: { "layout-2": _vm.showCL },
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: " ",
                                            attrs: {
                                              cols: "12",
                                              xl: "4",
                                              lg: "4",
                                              md: "4",
                                              sm: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass:
                                                  "pa-4 rounded-lg overflow-hidden",
                                                attrs: { elevation: "3" },
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass: "rounded-lg",
                                                  attrs: {
                                                    items: _vm.paramsDD11,
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    "item-text": "text",
                                                    "item-value": "value",
                                                    "return-object": "",
                                                  },
                                                  on: {
                                                    change: _vm.changedd11,
                                                  },
                                                  model: {
                                                    value: _vm.dd11.selected,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dd11,
                                                        "selected",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "dd11.selected",
                                                  },
                                                }),
                                                _c(
                                                  "v-card-text",
                                                  {
                                                    staticClass:
                                                      "pa-0 text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-left para-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.period.text
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "h2",
                                                      {
                                                        staticClass:
                                                          "counts count-overflow",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.dd11
                                                                .displayCurr
                                                                ? _vm.getCurrencyIcon(
                                                                    _vm.dd11
                                                                      .curr
                                                                  )
                                                                : ""
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.convertToShortCurr(
                                                                _vm.dd11.value
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.dd11
                                                                .displayPercentage
                                                                ? "%"
                                                                : ""
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "periods",
                                                      },
                                                      [
                                                        !_vm.dd11.positive
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "negative-value",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-minus"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        !_vm.dd11.positive
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "negative-value",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.dd11
                                                                      .percent
                                                                  ) + "%"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.dd11.positive
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "primary--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.dd11
                                                                      .percent
                                                                  ) + "%"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "mr-1 ml-2",
                                                          },
                                                          [_vm._v("/ ")]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "overview.period"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              "max-width":
                                                                "300px",
                                                              color:
                                                                "primarydark",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "ml-2 greyxdark--text",
                                                                              attrs: {
                                                                                small:
                                                                                  "",
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-help-circle-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ]
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.tooltip"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              xl: "4",
                                              lg: "4",
                                              md: "4",
                                              sm: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "pa-4 rounded-lg",
                                                attrs: { elevation: "3" },
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass: "rounded-lg",
                                                  attrs: {
                                                    items: _vm.paramsDD12,
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    "item-text": "text",
                                                    "item-value": "value",
                                                    "return-object": "",
                                                  },
                                                  on: {
                                                    change: _vm.changedd12,
                                                  },
                                                  model: {
                                                    value: _vm.dd12.selected,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dd12,
                                                        "selected",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "dd12.selected",
                                                  },
                                                }),
                                                _c(
                                                  "v-card-text",
                                                  {
                                                    staticClass:
                                                      "pa-0 text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-left para-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.period.text
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "h2",
                                                      {
                                                        staticClass:
                                                          "counts count-overflow",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.dd12
                                                                .displayCurr
                                                                ? _vm.getCurrencyIcon(
                                                                    _vm.dd12
                                                                      .curr
                                                                  )
                                                                : ""
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.convertToShortCurr(
                                                                _vm.dd12.value
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.dd12
                                                                .displayPercentage
                                                                ? "%"
                                                                : ""
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "periods",
                                                      },
                                                      [
                                                        !_vm.dd12.positive
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "negative-value",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-minus"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        !_vm.dd12.positive
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "negative-value",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.dd12
                                                                      .percent
                                                                  ) + " %"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.dd12.positive
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "primary--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.dd12
                                                                      .percent
                                                                  ) + " %"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "mr-1 ml-2",
                                                          },
                                                          [_vm._v("/ ")]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "overview.period"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              "max-width":
                                                                "300px",
                                                              color:
                                                                "primarydark",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "ml-2 greyxdark--text",
                                                                              attrs: {
                                                                                small:
                                                                                  "",
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-help-circle-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ]
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.tooltip"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: " ",
                                            attrs: {
                                              cols: "12",
                                              xl: "4",
                                              lg: "4",
                                              md: "4",
                                              sm: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "pa-4 rounded-lg",
                                                attrs: { elevation: "3" },
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass: "rounded-lg",
                                                  attrs: {
                                                    items: _vm.paramsDD13,
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    "item-text": "text",
                                                    "item-value": "value",
                                                    "return-object": "",
                                                  },
                                                  on: {
                                                    change: _vm.changedd13,
                                                  },
                                                  model: {
                                                    value: _vm.dd13.selected,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dd13,
                                                        "selected",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "dd13.selected",
                                                  },
                                                }),
                                                _c(
                                                  "v-card-text",
                                                  {
                                                    staticClass:
                                                      "pa-0 text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-left para-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.period.text
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "h2",
                                                      {
                                                        staticClass:
                                                          "counts count-overflow",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.dd13
                                                                .displayCurr
                                                                ? _vm.getCurrencyIcon(
                                                                    _vm.dd13
                                                                      .curr
                                                                  )
                                                                : ""
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.convertToShortCurr(
                                                                _vm.dd13.value
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.dd13
                                                                .displayPercentage
                                                                ? "%"
                                                                : ""
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "periods",
                                                      },
                                                      [
                                                        !_vm.dd13.positive
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "negative-value",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-minus"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        !_vm.dd13.positive
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "negative-value",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.dd13
                                                                      .percent
                                                                  ) + " %"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.dd13.positive
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "primary--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.dd13
                                                                      .percent
                                                                  ) + " %"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "mr-1 ml-2",
                                                          },
                                                          [_vm._v("/ ")]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "overview.period"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              "max-width":
                                                                "300px",
                                                              color:
                                                                "primarydark",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "ml-2 greyxdark--text",
                                                                              attrs: {
                                                                                small:
                                                                                  "",
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-help-circle-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ]
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.tooltip"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              xl: "4",
                                              lg: "4",
                                              md: "4",
                                              sm: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "pa-4 rounded-lg",
                                                attrs: { elevation: "3" },
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass: "rounded-lg",
                                                  attrs: {
                                                    items: _vm.paramsDD14,
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    "item-text": "text",
                                                    "item-value": "value",
                                                    "return-object": "",
                                                  },
                                                  on: {
                                                    change: _vm.changedd14,
                                                  },
                                                  model: {
                                                    value: _vm.dd14.selected,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dd14,
                                                        "selected",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "dd14.selected",
                                                  },
                                                }),
                                                _c(
                                                  "v-card-text",
                                                  {
                                                    staticClass:
                                                      "pa-0 text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-left para-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.period.text
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "h2",
                                                      {
                                                        staticClass:
                                                          "counts count-overflow",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.dd14
                                                                .displayCurr
                                                                ? _vm.getCurrencyIcon(
                                                                    _vm.dd14
                                                                      .curr
                                                                  )
                                                                : ""
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.convertToShortCurr(
                                                                _vm.dd14.value
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.dd14
                                                                .displayPercentage
                                                                ? "%"
                                                                : ""
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "periods",
                                                      },
                                                      [
                                                        !_vm.dd14.positive
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "negative-value",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-minus"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        !_vm.dd14.positive
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "negative-value",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.dd14
                                                                      .percent
                                                                  ) + " %"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.dd14.positive
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "primary--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.dd14
                                                                      .percent
                                                                  ) + " %"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "mr-1 ml-2",
                                                          },
                                                          [_vm._v("/ ")]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "overview.period"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              "max-width":
                                                                "300px",
                                                              color:
                                                                "primarydark",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "ml-2 greyxdark--text",
                                                                              attrs: {
                                                                                small:
                                                                                  "",
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-help-circle-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ]
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.tooltip"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              xl: "4",
                                              lg: "4",
                                              md: "4",
                                              sm: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "pa-4 rounded-lg",
                                                attrs: { elevation: "3" },
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass: "rounded-lg",
                                                  attrs: {
                                                    items: _vm.paramsDD15,
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    "item-text": "text",
                                                    "item-value": "value",
                                                    "return-object": "",
                                                  },
                                                  on: {
                                                    change: _vm.changedd15,
                                                  },
                                                  model: {
                                                    value: _vm.dd15.selected,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dd15,
                                                        "selected",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "dd15.selected",
                                                  },
                                                }),
                                                _c(
                                                  "v-card-text",
                                                  {
                                                    staticClass:
                                                      "pa-0 text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-left para-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.period.text
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "h2",
                                                      {
                                                        staticClass:
                                                          "counts count-overflow",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.dd15
                                                                .displayCurr
                                                                ? _vm.getCurrencyIcon(
                                                                    _vm.dd15
                                                                      .curr
                                                                  )
                                                                : ""
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.convertToShortCurr(
                                                                _vm.dd15.value
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.dd15
                                                                .displayPercentage
                                                                ? "%"
                                                                : ""
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "periods",
                                                      },
                                                      [
                                                        !_vm.dd15.positive
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "negative-value",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-minus"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        !_vm.dd15.positive
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "negative-value",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.dd15
                                                                      .percent
                                                                  ) + " %"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.dd15.positive
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "primary--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.dd15
                                                                      .percent
                                                                  ) + " %"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "mr-1 ml-2",
                                                          },
                                                          [_vm._v("/ ")]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "overview.period"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              "max-width":
                                                                "300px",
                                                              color:
                                                                "primarydark",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "ml-2 greyxdark--text",
                                                                              attrs: {
                                                                                small:
                                                                                  "",
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-help-circle-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ]
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.tooltip"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: " ",
                                            attrs: {
                                              cols: "12",
                                              xl: "4",
                                              lg: "4",
                                              md: "4",
                                              sm: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "pa-4 rounded-lg",
                                                attrs: { elevation: "3" },
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass: "rounded-lg",
                                                  attrs: {
                                                    items: _vm.paramsDD16,
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    "item-text": "text",
                                                    "item-value": "value",
                                                    "return-object": "",
                                                  },
                                                  on: {
                                                    change: _vm.changedd16,
                                                  },
                                                  model: {
                                                    value: _vm.dd16.selected,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dd16,
                                                        "selected",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "dd16.selected",
                                                  },
                                                }),
                                                _c(
                                                  "v-card-text",
                                                  {
                                                    staticClass:
                                                      "pa-0 text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-left para-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.period.text
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "h2",
                                                      {
                                                        staticClass:
                                                          "counts count-overflow",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.dd16
                                                                .displayCurr
                                                                ? _vm.getCurrencyIcon(
                                                                    _vm.dd16
                                                                      .curr
                                                                  )
                                                                : ""
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.convertToShortCurr(
                                                                _vm.dd16.value
                                                              )
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.dd16
                                                                .displayPercentage
                                                                ? "%"
                                                                : ""
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "periods",
                                                      },
                                                      [
                                                        !_vm.dd16.positive
                                                          ? _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "negative-value",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-minus"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        !_vm.dd16.positive
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "negative-value",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.dd16
                                                                      .percent
                                                                  ) + " %"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.dd16.positive
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "primary--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.dd16
                                                                      .percent
                                                                  ) + " %"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "mr-1 ml-2",
                                                          },
                                                          [_vm._v("/ ")]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "overview.period"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              "max-width":
                                                                "300px",
                                                              color:
                                                                "primarydark",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "ml-2 greyxdark--text",
                                                                              attrs: {
                                                                                small:
                                                                                  "",
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-help-circle-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ]
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.tooltip"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "h-100 funnel-outer",
                                    attrs: {
                                      cols: "12",
                                      xl: "6",
                                      lg: "6",
                                      md: "12",
                                      sm: "12",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          "pa-4 rounded-lg v-card v-sheet theme--light elevation-3",
                                      },
                                      [
                                        _vm.currOverview &&
                                        _vm.currOverview.grandTotal
                                          ? _c("vue-funnel-graph", {
                                              staticClass: "overflow-hidden",
                                              attrs: {
                                                width: _vm.width,
                                                height: _vm.height,
                                                labels: _vm.labels,
                                                values: _vm.values,
                                                colors: _vm.colors,
                                                "sub-labels": _vm.subLabels,
                                                direction: _vm.direction,
                                                animated: false,
                                                "display-percentage": false,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              {
                                staticClass: "row-top-less graph-map-wrap",
                                class: { "layout-3": _vm.showCL },
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "data-graph",
                                    attrs: { cols: "12", sm: "12", md: "6" },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          "px-4 py-6 rounded-lg h-100",
                                        attrs: { elevation: "3" },
                                      },
                                      [
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass: "pa-0 dash-cs-tab",
                                              attrs: { flat: "" },
                                            },
                                            [
                                              _c("v-toolbar", {
                                                attrs: {
                                                  flat: "",
                                                  dense: "",
                                                  prominent: "",
                                                  height: "10",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "extension",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "v-tabs",
                                                          {
                                                            model: {
                                                              value: _vm.tabs,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.tabs = $$v
                                                              },
                                                              expression:
                                                                "tabs",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.tabItems,
                                                            function (item) {
                                                              return _c(
                                                                "v-tab",
                                                                {
                                                                  key: item,
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.chartChanged(
                                                                        item
                                                                      )
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(item)
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ]),
                                              }),
                                              _c(
                                                "v-tabs-items",
                                                {
                                                  model: {
                                                    value: _vm.tabs,
                                                    callback: function ($$v) {
                                                      _vm.tabs = $$v
                                                    },
                                                    expression: "tabs",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-tab-item",
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass: "pa-0",
                                                          attrs: { flat: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              staticClass:
                                                                "px-0",
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12",
                                                                        sm: "6",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-sheet",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "white",
                                                                            elevation:
                                                                              "0",
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-select",
                                                                            {
                                                                              staticClass:
                                                                                "rounded-lg",
                                                                              attrs: {
                                                                                items:
                                                                                  _vm.params,
                                                                                required:
                                                                                  "",
                                                                                outlined:
                                                                                  "",
                                                                                dense:
                                                                                  "",
                                                                                "item-text":
                                                                                  "text",
                                                                                "item-value":
                                                                                  "value",
                                                                                "return-object":
                                                                                  "",
                                                                              },
                                                                              on: {
                                                                                change:
                                                                                  _vm.changedd22,
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .dd22
                                                                                    .selected,
                                                                                callback: function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.dd22,
                                                                                    "selected",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "dd22.selected",
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "px-0",
                                                            },
                                                            [
                                                              _c(
                                                                "DeviceChart",
                                                                {
                                                                  attrs: {
                                                                    height: 220,
                                                                    deviceData:
                                                                      _vm.deviceData,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-tab-item",
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass: "pa-0",
                                                          attrs: { flat: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              staticClass:
                                                                "px-0",
                                                            },
                                                            [
                                                              _c(
                                                                "v-row",
                                                                [
                                                                  _c(
                                                                    "v-col",
                                                                    {
                                                                      attrs: {
                                                                        cols:
                                                                          "12",
                                                                        sm: "6",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-sheet",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "white",
                                                                            elevation:
                                                                              "0",
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-select",
                                                                            {
                                                                              staticClass:
                                                                                "no-field-details small-select rounded-lg",
                                                                              attrs: {
                                                                                items:
                                                                                  _vm.params,
                                                                                required:
                                                                                  "",
                                                                                outlined:
                                                                                  "",
                                                                                dense:
                                                                                  "",
                                                                                "item-text":
                                                                                  "text",
                                                                                "item-value":
                                                                                  "value",
                                                                                "return-object":
                                                                                  "",
                                                                              },
                                                                              on: {
                                                                                change:
                                                                                  _vm.changedd21,
                                                                              },
                                                                              model: {
                                                                                value:
                                                                                  _vm
                                                                                    .dd21
                                                                                    .selected,
                                                                                callback: function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.dd21,
                                                                                    "selected",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                                expression:
                                                                                  "dd21.selected",
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "px-0",
                                                            },
                                                            [
                                                              _c(
                                                                "GenderChart",
                                                                {
                                                                  attrs: {
                                                                    height: 220,
                                                                    genderData:
                                                                      _vm.genderData,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "data-map",
                                    attrs: { cols: "12", sm: "12", md: "6" },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          "pa-4 rounded-lg extra-padding h-100",
                                        attrs: { elevation: "3" },
                                      },
                                      [
                                        _c(
                                          "v-card-title",
                                          { staticClass: "pt-0 px-0" },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "6",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-sheet",
                                                      {
                                                        attrs: {
                                                          color: "white",
                                                          elevation: "0",
                                                        },
                                                      },
                                                      [
                                                        _c("v-select", {
                                                          staticClass:
                                                            "rounded-lg",
                                                          attrs: {
                                                            items: _vm.params,
                                                            required: "",
                                                            outlined: "",
                                                            dense: "",
                                                            "item-text": "text",
                                                            "item-value":
                                                              "value",
                                                            "return-object": "",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.changedd23,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.dd23.selected,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.dd23,
                                                                "selected",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "dd23.selected",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-text",
                                          { staticClass: "px-0" },
                                          [
                                            _c("MapChart", {
                                              attrs: {
                                                countryData: _vm.locationData,
                                                highColor: "#365ca8",
                                                lowColor: "#cce4e6",
                                                countryStrokeColor: "#909090",
                                                defaultCountryFillColor:
                                                  "#dadada",
                                                currencyAdd: false,
                                                positionLeftTooltip: -230,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "12", md: "6" } },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          "pa-4 rounded-lg extra-padding common-header-table h-100",
                                        attrs: { elevation: "3" },
                                      },
                                      [
                                        _c(
                                          "v-card-title",
                                          {
                                            staticClass:
                                              "subtitle-1 pt-0 font-weight-medium px-0 justify-center",
                                          },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "common-para" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "overview.topKeywords"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-sheet",
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "white",
                                                  elevation: "0",
                                                  height: "40",
                                                  width: "auto",
                                                  "max-width": "150",
                                                },
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass: "rounded-lg",
                                                  attrs: {
                                                    items: _vm.paramsLimited,
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    "item-text": "text",
                                                    "item-value": "value",
                                                    "return-object": "",
                                                  },
                                                  on: {
                                                    change: _vm.changedd31,
                                                  },
                                                  model: {
                                                    value: _vm.dd31.selected,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dd31,
                                                        "selected",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "dd31.selected",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-text",
                                          { staticClass: "text-center pa-0" },
                                          [
                                            _c("v-simple-table", {
                                              staticClass: "table-style-3",
                                              attrs: { dense: "", light: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function () {
                                                    return [
                                                      _c("thead", [
                                                        _c("tr", [
                                                          _c(
                                                            "th",
                                                            {
                                                              staticClass:
                                                                "text-left",
                                                              attrs: {
                                                                id: "Keyword",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.key"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "th",
                                                            {
                                                              staticClass:
                                                                "text-left",
                                                              attrs: {
                                                                id: "Spend",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.spend"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "th",
                                                            {
                                                              staticClass:
                                                                "text-left",
                                                              attrs: {
                                                                id:
                                                                  "Impressions",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.impressions"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "th",
                                                            {
                                                              staticClass:
                                                                "text-left",
                                                              attrs: {
                                                                id: "Taps",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.taps"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "th",
                                                            {
                                                              staticClass:
                                                                "text-left",
                                                              attrs: {
                                                                id: "Count",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.count"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]),
                                                      _vm.topKeywords.length > 0
                                                        ? _c(
                                                            "tbody",
                                                            _vm._l(
                                                              _vm.topKeywords,
                                                              function (item) {
                                                                return _c(
                                                                  "tr",
                                                                  {
                                                                    key:
                                                                      item.id,
                                                                  },
                                                                  [
                                                                    item
                                                                      ? _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-left",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.name
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    item
                                                                      ? _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-left",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.getCurrencyIcon(
                                                                                    item.currency
                                                                                  )
                                                                                ) +
                                                                                " " +
                                                                                _vm._s(
                                                                                  _vm.numFormat(
                                                                                    item.localSpend.toFixed(
                                                                                      2
                                                                                    )
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    item
                                                                      ? _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-left",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.numFormat(
                                                                                  item.impressions
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    item
                                                                      ? _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-left",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.numFormat(
                                                                                  item.taps
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    item
                                                                      ? _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-left",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.goalEventCount
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-actions",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  depressed: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.goToDashBoard(
                                                      "keywords"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("overview.goToKey")
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("mdi-chevron-right")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "12", md: "6" } },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          "pa-4 rounded-lg common-header-table h-100",
                                        attrs: {
                                          elevation: "3",
                                          height: "100%",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card-title",
                                          {
                                            staticClass:
                                              "subtitle-1 font-weight-medium px-0 justify-center pt-0",
                                          },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "common-para" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "overview.topAdgroup"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-sheet",
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "white",
                                                  elevation: "0",
                                                  height: "40",
                                                  width: "auto",
                                                  "max-width": "150",
                                                },
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass: "rounded-lg",
                                                  attrs: {
                                                    items: _vm.paramsLimited,
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    "item-text": "text",
                                                    "item-value": "value",
                                                    "return-object": "",
                                                  },
                                                  on: {
                                                    change: _vm.changedd32,
                                                  },
                                                  model: {
                                                    value: _vm.dd32.selected,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.dd32,
                                                        "selected",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "dd32.selected",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-text",
                                          { staticClass: "text-center pa-0" },
                                          [
                                            _c("v-simple-table", {
                                              staticClass: "table-style-3",
                                              attrs: { dense: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function () {
                                                    return [
                                                      _c("thead", [
                                                        _c("tr", [
                                                          _c(
                                                            "th",
                                                            {
                                                              staticClass:
                                                                "text-left",
                                                              attrs: {
                                                                id: "AdGroup",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.adgroup"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "th",
                                                            {
                                                              staticClass:
                                                                "text-left",
                                                              attrs: {
                                                                id: "Spend",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.spend"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "th",
                                                            {
                                                              staticClass:
                                                                "text-left",
                                                              attrs: {
                                                                id:
                                                                  "Impressions",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.impressions"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "th",
                                                            {
                                                              staticClass:
                                                                "text-left",
                                                              attrs: {
                                                                id: "Taps",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.taps"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "th",
                                                            {
                                                              staticClass:
                                                                "text-left",
                                                              attrs: {
                                                                id: "Count",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "overview.count"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]),
                                                      _vm.topAdgroups.length > 0
                                                        ? _c(
                                                            "tbody",
                                                            _vm._l(
                                                              _vm.topAdgroups,
                                                              function (item) {
                                                                return _c(
                                                                  "tr",
                                                                  {
                                                                    key:
                                                                      item.id,
                                                                  },
                                                                  [
                                                                    item
                                                                      ? _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-left",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.name
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    item
                                                                      ? _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-left",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.getCurrencyIcon(
                                                                                    item.currency
                                                                                  )
                                                                                ) +
                                                                                " " +
                                                                                _vm._s(
                                                                                  _vm.numFormat(
                                                                                    item.localSpend.toFixed(
                                                                                      2
                                                                                    )
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    item
                                                                      ? _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-left",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.numFormat(
                                                                                  item.impressions
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    item
                                                                      ? _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-left",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.numFormat(
                                                                                  item.taps
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    item
                                                                      ? _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-left",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.numFormat(
                                                                                  item.goalEventCount
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-actions",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  depressed: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.goToDashBoard(
                                                      "adgroups"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "overview.goToAdgroup"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("mdi-chevron-right")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.showCL
                          ? _c(
                              "div",
                              { staticClass: "col-right-main" },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-width": "466",
                                      elevation: "1",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card-title",
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("checklist.title")) +
                                            " "
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { elevation: "0", icon: "" },
                                            on: {
                                              click: function ($event) {
                                                _vm.showCL = false
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  width: "16",
                                                  height: "16",
                                                  viewBox: "0 0 16 16",
                                                  fill: "none",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d:
                                                      "M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z",
                                                    fill: "black",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "titleText" },
                                          [
                                            _c("v-icon", {
                                              staticClass: "mr-2",
                                              attrs: { color: _vm.color0 },
                                              domProps: {
                                                textContent: _vm._s(_vm.check0),
                                              },
                                            }),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/integrations",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "checklist.integrations"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass: "cust-list",
                                            attrs: { lang: _vm.locale },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    size: "15",
                                                    color: _vm.color1,
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check1
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/integrations",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.searchads"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass: "cust-list",
                                            attrs: { lang: _vm.locale },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    size: "15",
                                                    color: _vm.color6,
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check6
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/integrations",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.slack"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass: "cust-list",
                                            attrs: { lang: _vm.locale },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    size: "15",
                                                    color: _vm.mmpIntColor,
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.mmpIntCheck
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/integrations",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.mmpIntegration"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-2",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "titleText" },
                                          [
                                            _c("v-icon", {
                                              staticClass: "mr-2",
                                              attrs: { color: _vm.color11 },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.check11
                                                ),
                                              },
                                            }),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "/keyword-auction-insights",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("checklist.kai")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass: "cust-list",
                                            attrs: { lang: _vm.locale },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color2,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check2
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        "/keyword-auction-insights?tab=comp",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.competitorMsg"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color8,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check8
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        "/keyword-auction-insights?tab=app",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.searchKeywords"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color9,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check9
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        "/keyword-auction-insights?tab=key",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.seeApps"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color10,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check10
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        "/keyword-auction-insights?tab=top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.topCharts"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-2",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "titleText" },
                                          [
                                            _c("v-icon", {
                                              staticClass: "mr-2",
                                              attrs: { color: _vm.color16 },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.check16
                                                ),
                                              },
                                            }),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/create-campaign",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("checklist.campaign")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass: "cust-list",
                                            attrs: { lang: _vm.locale },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color12,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check12
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/create-campaign",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.initCampaign"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color13,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check13
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/create-campaign",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.createAdgroup"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color14,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check14
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/create-campaign",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.addKeywords"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color15,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check15
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/create-campaign",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.addCreativeSet"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color3,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check3
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/ads-manager",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.goLive"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-2",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "titleText" },
                                          [
                                            _c("v-icon", {
                                              staticClass: "mr-2",
                                              attrs: { color: _vm.color5 },
                                              domProps: {
                                                textContent: _vm._s(_vm.check5),
                                              },
                                            }),
                                            _c(
                                              "a",
                                              { attrs: { href: "/overview" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "checklist.reviewMetrics"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-2",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "titleText" },
                                          [
                                            _c("v-icon", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                color: _vm.campGenColor,
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.campGenCheck
                                                ),
                                              },
                                            }),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/campaign-generator",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("checklist.campGen")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-2",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "titleText" },
                                          [
                                            _c("v-icon", {
                                              staticClass: "mr-2",
                                              attrs: { color: _vm.color4 },
                                              domProps: {
                                                textContent: _vm._s(_vm.check4),
                                              },
                                            }),
                                            _c(
                                              "a",
                                              { attrs: { href: "/arrules" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("checklist.automate")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-2",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "titleText" },
                                          [
                                            _c("v-icon", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                color:
                                                  _vm.scheduledReportsColor,
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.scheduledReportsCheck
                                                ),
                                              },
                                            }),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/schedule-reports",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "checklist.scheduledReports"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-2",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "titleText pb-4" },
                                          [
                                            _c("v-icon", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                color: _vm.createMmpGoalsColor,
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.createMmpGoalsCheck
                                                ),
                                              },
                                            }),
                                            _c(
                                              "a",
                                              { attrs: { href: "/goals" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "checklist.createMmpGoals"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
          _c("DataRequests", { attrs: { openDR: _vm.triggerDR } }),
          _c("TaskDialog", { attrs: { openDR: _vm.tDR } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }