var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "700", persistent: "" },
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "v-modal-fixed style-2" },
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline d-flex justify-space-between px-5 py-3 v-modal-header",
            },
            [
              _c("h5", { staticClass: "text-h6" }, [_vm._v("Your Task")]),
              _c(
                "v-icon",
                {
                  staticClass: "black--text",
                  on: {
                    click: function ($event) {
                      _vm.open = false
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-4 pb-4" },
            [
              _c("v-spacer"),
              [
                _c("v-data-table", {
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.tasks,
                    "hide-default-footer": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item.category",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c(
                            "button",
                            {
                              staticStyle: { cursor: "pointer" },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.showCategoryTasks(item.categoryId)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.category))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.lastQueuedAt",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                new Date(item.lastQueuedAt).toLocaleString()
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.countRemain",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " + _vm._s(item.cr + "/" + item.totalCount) + " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              _c("v-divider", { staticClass: "mt-4" }),
              _vm.showTasks
                ? _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _vm._l(_vm.catTasks, function (task, i) {
                        return _c(
                          "v-list-item",
                          { key: i, staticClass: "mt-2" },
                          [
                            _c(
                              "v-card",
                              { staticClass: "vcard" },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [
                                    task.categoryId === 1
                                      ? _c("v-icon", [
                                          _vm._v("mdi-auto-upload"),
                                        ])
                                      : _vm._e(),
                                    task.categoryId === 2
                                      ? _c("v-icon", [
                                          _vm._v(
                                            "mdi-script-text-play-outline"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleShowMsgs(i)
                                      },
                                    },
                                  },
                                  [
                                    task.categoryId === 2
                                      ? _c("v-list-item-title", [
                                          _c("a", [
                                            _vm._v(
                                              _vm._s(task.ruleName) +
                                                " - Remain ( " +
                                                _vm._s(
                                                  task.cr +
                                                    "/" +
                                                    task.totalCount
                                                ) +
                                                ") - Last queued at " +
                                                _vm._s(
                                                  new Date(
                                                    task.lastQueuedAt
                                                  ).toLocaleString()
                                                )
                                            ),
                                          ]),
                                        ])
                                      : _c("v-list-item-title", [
                                          _c("a", [
                                            _vm._v(
                                              "ID: " +
                                                _vm._s(task.taskId) +
                                                " - Remain ( " +
                                                _vm._s(
                                                  task.cr +
                                                    "/" +
                                                    task.totalCount
                                                ) +
                                                ") - Last queued at " +
                                                _vm._s(
                                                  new Date(
                                                    task.lastQueuedAt
                                                  ).toLocaleString()
                                                )
                                            ),
                                          ]),
                                        ]),
                                    _c(
                                      "v-list-item-subtitle",
                                      [
                                        _c("v-progress-linear", {
                                          staticClass: "mr-3",
                                          attrs: {
                                            height: "3",
                                            value: task.progress,
                                            color: "primary",
                                            stream: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _c("a", [
                                        _vm._v(
                                          "Success from Apple: " +
                                            _vm._s(task.successCount) +
                                            ", Error from Apple: " +
                                            _vm._s(task.failedCount) +
                                            ", Unable to execute: " +
                                            _vm._s(task.errorCount)
                                        ),
                                      ]),
                                    ]),
                                    task.showMsgs
                                      ? _c(
                                          "v-list-item-subtitle",
                                          _vm._l(
                                            task.messages,
                                            function (msg, i) {
                                              return _c(
                                                "v-label",
                                                { key: i },
                                                [
                                                  _c("v-divider", {
                                                    staticClass: "mt-2",
                                                  }),
                                                  _c("br"),
                                                  _vm._v(
                                                    " " + _vm._s(msg) + " "
                                                  ),
                                                  _c("br"),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                task.cr > 0
                                  ? _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-circle-slice-2 mdi-spin"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.confirmKBBU(
                                                  task.taskId
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("mdi-delete-outline")]
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                            _c("br"),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "500" },
          model: {
            value: _vm.openDeleteConfirmation,
            callback: function ($$v) {
              _vm.openDeleteConfirmation = $$v
            },
            expression: "openDeleteConfirmation",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "d-flex align-center justify-center flex-column" },
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3",
                },
                [
                  _c("h5", [_vm._v(" Delete Task")]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "white--text",
                      on: {
                        click: function ($event) {
                          _vm.openDeleteConfirmation = false
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "d-flex align-start px-6 py-8" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "font-32 mr-3 ",
                      attrs: { color: "primary" },
                    },
                    [_vm._v("mdi-delete-circle")]
                  ),
                  _c("v-card-title", { staticClass: "text-subtitle-1 pa-0" }, [
                    _vm._v(" Are you sure want to delete the task? "),
                  ]),
                ],
                1
              ),
              _c(
                "v-card-actions",
                {
                  staticClass:
                    "d-flex justify-end align-center w-100 pb-6 pt-0 px-6",
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { width: "100px", color: " " },
                      on: {
                        click: function ($event) {
                          _vm.openDeleteConfirmation = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("deleteCampaign.cancel")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { width: "60px", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteKBBU(_vm.taskToDelete)
                        },
                      },
                    },
                    [_vm._v(" Ok ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }