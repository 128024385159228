<template flex>
  <div class="dashboard">
    <v-app>
      <div class="inner-container">
        <!-- Provides the application the proper gutter -->
        <div class="top-page-header page-header-with-banner" :lang="locale">
          <v-row class="select-area mt-1 d-flex align-center">
            <v-col cols="12" xl="2" lg="2" md="4" sm="4" class="py-2">
              <v-sheet color="white" elevation="0" class="color-select-icon with-small">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="searchApp"
                  :items="searchedApps"
                  :loading="loadingApps"
                  item-text="appName"
                  item-value="adamId"
                  required
                  outlined
                  dense
                  prepend-inner-icon="mdi-cellphone"
                  return-object
                  @change="changeApp"
                  :disabled="searchedApps.length < 2"
                >
                  <template v-slot:item="data">
                    <template>
                      <v-img
                        class="rounded-lg"
                        color="secondary"
                        :lazy-src="data.item.appIcon"
                        max-width="30"
                        max-height="30"
                        min-width="20"
                        min-height="20"
                        :src="data.item.appIcon"
                      ></v-img>
                      <v-list-item-content class="ml-2">
                        <v-list-item-title>
                          {{ data.item.appName }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-sheet>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="4" sm="4" class="py-2 mb-2">
              <div class="d-flex align-center justify-end flex-wrap w-100 overview-date-picker">
                <v-menu
                  ref="menu"
                  v-model="rangePicker"
                  :close-on-content-click="false"
                  :return-value.sync="rangePicker"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="overview-menu date-picker"
                  right
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      style="font-size: revert"
                      v-model="filterDateRangeText"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      class="v-date-picker-cs w-sm-100"
                    ></v-text-field>
                  </template>
                  <template>
                    <div style="display: flex">
                      <div style="flex: auto">
                        <ul class="flex-container column mt-3" style="list-style-type: none">
                          <li class="flex-item">
                            <v-chip class="ma-1" label @click="selectPreset('TODAY')">
                              {{ $t('dateRangePresets.today') }}
                            </v-chip>
                          </li>
                          <li class="flex-item">
                            <v-chip class="ma-1" label @click="selectPreset('YESTERDAY')">
                              {{ $t('dateRangePresets.yesterday') }}
                            </v-chip>
                          </li>
                          <li class="flex-item">
                            <v-chip class="ma-1" label @click="selectPreset('WEEKTODATE')">
                              {{ $t('dateRangePresets.weekToDate') }}
                            </v-chip>
                          </li>
                          <li class="flex-item">
                            <v-chip class="ma-1" label @click="selectPreset('LASTWEEK')">
                              {{ $t('dateRangePresets.lastWeek') }}
                            </v-chip>
                          </li>
                          <li class="flex-item">
                            <v-chip class="ma-1" label @click="selectPreset('LASTSEVENDAYS')">
                              {{ $t('dateRangePresets.lastSevenDays') }}
                            </v-chip>
                          </li>
                          <li class="flex-item">
                            <v-chip class="ma-1" label @click="selectPreset('MONTHTODATE')">
                              {{ $t('dateRangePresets.monthToDate') }}
                            </v-chip>
                          </li>
                          <li class="flex-item">
                            <v-chip class="ma-1" label @click="selectPreset('LASTMONTH')">
                              {{ $t('dateRangePresets.lastMonth') }}
                            </v-chip>
                          </li>
                          <li class="flex-item">
                            <v-chip class="ma-1" label @click="selectPreset('LASTTHIRTYDAY')">
                              {{ $t('dateRangePresets.lastThirtyDays') }}
                            </v-chip>
                          </li>
                        </ul>
                      </div>
                      <div style="flex: auto">
                        <v-date-picker v-model="filterDateRangeModel" :max="maxDate" no-title scrollable range>
                          <v-btn text color="grey" @click="rangePicker = false">Cancel</v-btn>
                          <v-btn
                            :disabled="filterDateRangeModel && filterDateRangeModel.length !== 2"
                            text
                            color="primary"
                            @click="changePeriod"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </div>
                    </div>
                  </template>
                </v-menu>
              </div>
            </v-col>

            <v-col cols="12" xl="2" lg="2" md="4" sm="4" class="mb-2 py-2">
              <v-sheet color="white" elevation="0" class="color-select-icon w-100">
                <v-tooltip bottom v-if="searchApp.adamId === 0" color="primarydark">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="w-100" v-bind="attrs" v-on="on">
                      <v-autocomplete
                        class="rounded-lg w-100"
                        v-model="selectedGoal"
                        :items="appGoals || []"
                        item-text="name"
                        item-value="_id"
                        required
                        outlined
                        dense
                        prepend-inner-icon="mdi-bullseye"
                        return-object
                        @change="changePeriod"
                        :disabled="disableGoalDD"
                      >
                      </v-autocomplete>
                    </div>
                  </template>
                  <span>{{ $t('overview.selectGoalFirstSelectApp') }}</span>
                </v-tooltip>
                <v-tooltip bottom v-else color="primarydark" :open-delay="3000">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="" v-bind="attrs" v-on="on">
                      <v-autocomplete
                        class="rounded-lg"
                        v-model="selectedGoal"
                        :items="appGoals || []"
                        item-text="name"
                        item-value="_id"
                        required
                        outlined
                        dense
                        prepend-inner-icon="mdi-bullseye"
                        return-object
                        @change="changePeriod"
                        :disabled="disableGoalDD"
                      >
                      </v-autocomplete>
                    </div>
                  </template>
                  <span v-if="disableGoalDD">You do not have any goals created for this App</span>
                  <span v-if="!disableGoalDD">Filter data by selecting a goal here</span>
                </v-tooltip>
              </v-sheet>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="12" sm="12" class="mb-2 d-flex py-2 flex-wrap justify-space-between w-100">
              <div class="d-flex">
                <v-tooltip bottom color="primarydark">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="br-8 px-6 h-4 br d-flex align-center justify-center white-bg kai-sync cursor-pointer ml-0 mr-1"
                      color=" "
                      v-bind="attrs"
                      v-on="on"
                    >
                      <div
                        class="primary--text d-flex align-center justify-center flex-column font-13 mt-1 light-font font-600"
                        @click="openDataRequests"
                      >
                        <v-progress-linear :value="drProgress" color="primary" class="mb-0 w-100"></v-progress-linear>
                        {{ $t('pages.kar') }} - {{ parseInt(drProgress) }}%
                      </div>
                    </div>
                  </template>
                  <span>See your data requests</span>
                </v-tooltip>
                <v-tooltip bottom color="primarydark">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="br-8 px-6 h-4 br d-flex align-center justify-center white-bg kai-sync cursor-pointer ml-0"
                      color=" "
                      v-bind="attrs"
                      v-on="on"
                    >
                      <div
                        class="primary--text d-flex justify-center flex-column font-13 mt-1 light-font font-600 w-100"
                        @click="openTaskRequests"
                      >
                        <v-progress-linear :value="drProgress" color="primary" class="mb-0 w-100"></v-progress-linear>
                        Task - 0%
                      </div>
                    </div>
                  </template>
                  <span>Task</span>
                </v-tooltip>
              </div>
              <div class="csv-logo d-flex align-center justify-end" @click="showExportReportModel">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-img v-bind="attrs" v-on="on" src="../assets/images/csv.svg" class="cursor-pointer"></v-img>
                  </template>
                  <span>{{ $t('overview.exportCSV') }}</span>
                </v-tooltip>
              </div>
            </v-col>
            <template v-if="exportReportModel">
              <div justify="center" class="export-csv">
                <v-card elevation="0" class="export-csv-inner" attach>
                  <v-date-picker no-title scrollable elevation="2" v-model="filterDateRangeModel" range class="w-100" :max="getMaxDate()">
                    <div class="d-flex flex-column pa-3 pr-7 w-100 export-heading">
                      <div class="d-flex justify-space-between align-center w-100">
                        <h5 class="primary--text">Export CSV</h5>
                        <v-icon color="primary" @click="closeExportReportModel">mdi-close</v-icon>
                      </div>
                      <v-divider horizontal class="my-2 w-100"></v-divider>
                    </div>

                    <div class="d-flex flex-column w-100 align-start justify-start pa-3">
                      <div class="d-flex">
                        <v-select
                          class="mt-1 w-100 mb-2"
                          v-model="selectedDatasets"
                          :items="reportDatasets"
                          placeholder="Report Datasets"
                          label="Report Datasets"
                          item-text="text"
                          return-object
                          required
                          outlined
                          dense
                          multiple
                          item-disabled="disabled"
                          :key="exportReportKey"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                              <span>{{ item.text }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption"> (+{{ selectedDatasets.length - 1 }} others) </span>
                          </template>
                        </v-select>

                        <v-tooltip bottom max-width="300px" color="primarydark">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-2 greyxdark--text inside-select-icon" small v-bind="attrs" v-on="on"
                              >mdi-help-circle-outline</v-icon
                            >
                          </template>
                          <span>Datasets that are selected in the dropdown will be exported in report</span>
                        </v-tooltip>
                      </div>

                      <label class="sticky-label pl-2">Email address</label>
                      <v-text-field dense class="w-100" v-model="receiverEmail"></v-text-field>

                      <v-btn class="w-100 mt-4" color="primary" @click="exportReport"> Send Report </v-btn>
                    </div>
                  </v-date-picker>
                </v-card>
              </div>
            </template>
          </v-row>
        </div>

        <v-container fluid class="v-main-content overview-top">
          <template fluid>
            <div class="colFullOverview" v-bind:class="{ open: showCL }">
              <v-btn
                color="primary "
                class="floatingBtn rounded-r-xl-0 rounded-l-xl"
                elevation="0"
                dark
                absolute
                top
                right
                @click="showCheckList()"
                v-if="existCL"
              >
                <v-icon class="first-ic">mdi-check-circle</v-icon>
                <v-icon class="second-ic">mdi-close-circle</v-icon>
              </v-btn>

              <div class="col-left-main">
                <v-row>
                  <v-col cols="12" xl="6" lg="6" md="12" sm="12" class="h-100">
                    <v-row class="v-fixed-top-section style-3" v-bind:class="{ 'layout-2': showCL }">
                      <v-col cols="12" xl="4" lg="4" md="4" sm="6" class=" ">
                        <v-card elevation="3" class="pa-4 rounded-lg overflow-hidden">
                          <v-select
                            class="rounded-lg"
                            v-model="dd11.selected"
                            :items="paramsDD11"
                            required
                            outlined
                            dense
                            @change="changedd11"
                            item-text="text"
                            item-value="value"
                            return-object
                          ></v-select>
                          <v-card-text class="pa-0 text-center">
                            <p class="text-left para-text">{{ period.text }}</p>

                            <h2 class="counts count-overflow">
                              {{ dd11.displayCurr ? getCurrencyIcon(dd11.curr) : '' }} {{ convertToShortCurr(dd11.value) }}
                              {{ dd11.displayPercentage ? '%' : '' }}
                            </h2>

                            <div class="periods">
                              <v-icon v-if="!dd11.positive" class="negative-value">mdi-minus</v-icon>
                              <span v-if="!dd11.positive" class="negative-value">{{ dd11.percent }}%</span>
                              <span v-if="dd11.positive" class="primary--text">{{ dd11.percent }}%</span>
                              <span class="mr-1 ml-2">/ </span>
                              <span>{{ $t('overview.period') }}</span>
                              <v-tooltip bottom max-width="300px" color="primarydark">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon class="ml-2 greyxdark--text" small v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                                </template>
                                <span>{{ $t('overview.tooltip') }}</span>
                              </v-tooltip>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>

                      <v-col cols="12" xl="4" lg="4" md="4" sm="6" class="">
                        <v-card elevation="3" class="pa-4 rounded-lg">
                          <v-select
                            class="rounded-lg"
                            v-model="dd12.selected"
                            :items="paramsDD12"
                            required
                            outlined
                            dense
                            @change="changedd12"
                            item-text="text"
                            item-value="value"
                            return-object
                          ></v-select>
                          <v-card-text class="pa-0 text-center">
                            <p class="text-left para-text">{{ period.text }}</p>

                            <h2 class="counts count-overflow">
                              {{ dd12.displayCurr ? getCurrencyIcon(dd12.curr) : '' }} {{ convertToShortCurr(dd12.value) }}
                              {{ dd12.displayPercentage ? '%' : '' }}
                            </h2>

                            <div class="periods">
                              <v-icon v-if="!dd12.positive" class="negative-value">mdi-minus</v-icon>
                              <span v-if="!dd12.positive" class="negative-value">{{ dd12.percent }} %</span>
                              <span v-if="dd12.positive" class="primary--text">{{ dd12.percent }} %</span>
                              <span class="mr-1 ml-2">/ </span>
                              <span>{{ $t('overview.period') }}</span>
                              <v-tooltip bottom max-width="300px" color="primarydark">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon class="ml-2 greyxdark--text" small v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                                </template>
                                <span>{{ $t('overview.tooltip') }}</span>
                              </v-tooltip>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>

                      <v-col cols="12" xl="4" lg="4" md="4" sm="6" class=" ">
                        <v-card elevation="3" class="pa-4 rounded-lg">
                          <v-select
                            class="rounded-lg"
                            v-model="dd13.selected"
                            :items="paramsDD13"
                            required
                            outlined
                            dense
                            @change="changedd13"
                            item-text="text"
                            item-value="value"
                            return-object
                          ></v-select>
                          <v-card-text class="pa-0 text-center">
                            <p class="text-left para-text">{{ period.text }}</p>

                            <h2 class="counts count-overflow">
                              {{ dd13.displayCurr ? getCurrencyIcon(dd13.curr) : '' }} {{ convertToShortCurr(dd13.value) }}
                              {{ dd13.displayPercentage ? '%' : '' }}
                            </h2>

                            <div class="periods">
                              <v-icon v-if="!dd13.positive" class="negative-value">mdi-minus</v-icon>
                              <span v-if="!dd13.positive" class="negative-value">{{ dd13.percent }} %</span>
                              <span v-if="dd13.positive" class="primary--text">{{ dd13.percent }} %</span>
                              <span class="mr-1 ml-2">/ </span>
                              <span>{{ $t('overview.period') }}</span>
                              <v-tooltip bottom max-width="300px" color="primarydark">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon class="ml-2 greyxdark--text" small v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                                </template>
                                <span>{{ $t('overview.tooltip') }}</span>
                              </v-tooltip>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>

                      <v-col cols="12" xl="4" lg="4" md="4" sm="6" class="">
                        <v-card elevation="3" class="pa-4 rounded-lg">
                          <v-select
                            class="rounded-lg"
                            v-model="dd14.selected"
                            :items="paramsDD14"
                            required
                            outlined
                            dense
                            @change="changedd14"
                            item-text="text"
                            item-value="value"
                            return-object
                          ></v-select>
                          <v-card-text class="pa-0 text-center">
                            <p class="text-left para-text">{{ period.text }}</p>

                            <h2 class="counts count-overflow">
                              {{ dd14.displayCurr ? getCurrencyIcon(dd14.curr) : '' }} {{ convertToShortCurr(dd14.value) }}
                              {{ dd14.displayPercentage ? '%' : '' }}
                            </h2>

                            <div class="periods">
                              <v-icon v-if="!dd14.positive" class="negative-value">mdi-minus</v-icon>
                              <span v-if="!dd14.positive" class="negative-value">{{ dd14.percent }} %</span>
                              <span v-if="dd14.positive" class="primary--text">{{ dd14.percent }} %</span>
                              <span class="mr-1 ml-2">/ </span>
                              <span>{{ $t('overview.period') }}</span>
                              <v-tooltip bottom max-width="300px" color="primarydark">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon class="ml-2 greyxdark--text" small v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                                </template>
                                <span>{{ $t('overview.tooltip') }}</span>
                              </v-tooltip>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>

                      <v-col cols="12" xl="4" lg="4" md="4" sm="6" class="">
                        <v-card elevation="3" class="pa-4 rounded-lg">
                          <v-select
                            class="rounded-lg"
                            v-model="dd15.selected"
                            :items="paramsDD15"
                            required
                            outlined
                            dense
                            @change="changedd15"
                            item-text="text"
                            item-value="value"
                            return-object
                          ></v-select>
                          <v-card-text class="pa-0 text-center">
                            <p class="text-left para-text">{{ period.text }}</p>

                            <h2 class="counts count-overflow">
                              {{ dd15.displayCurr ? getCurrencyIcon(dd15.curr) : '' }} {{ convertToShortCurr(dd15.value) }}
                              {{ dd15.displayPercentage ? '%' : '' }}
                            </h2>

                            <div class="periods">
                              <v-icon v-if="!dd15.positive" class="negative-value">mdi-minus</v-icon>
                              <span v-if="!dd15.positive" class="negative-value">{{ dd15.percent }} %</span>
                              <span v-if="dd15.positive" class="primary--text">{{ dd15.percent }} %</span>
                              <span class="mr-1 ml-2">/ </span>
                              <span>{{ $t('overview.period') }}</span>
                              <v-tooltip bottom max-width="300px" color="primarydark">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon class="ml-2 greyxdark--text" small v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                                </template>
                                <span>{{ $t('overview.tooltip') }}</span>
                              </v-tooltip>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>

                      <v-col cols="12" xl="4" lg="4" md="4" sm="6" class=" ">
                        <v-card elevation="3" class="pa-4 rounded-lg">
                          <v-select
                            class="rounded-lg"
                            v-model="dd16.selected"
                            :items="paramsDD16"
                            required
                            outlined
                            dense
                            @change="changedd16"
                            item-text="text"
                            item-value="value"
                            return-object
                          ></v-select>
                          <v-card-text class="pa-0 text-center">
                            <p class="text-left para-text">{{ period.text }}</p>

                            <h2 class="counts count-overflow">
                              {{ dd16.displayCurr ? getCurrencyIcon(dd16.curr) : '' }} {{ convertToShortCurr(dd16.value) }}
                              {{ dd16.displayPercentage ? '%' : '' }}
                            </h2>

                            <div class="periods">
                              <v-icon v-if="!dd16.positive" class="negative-value">mdi-minus</v-icon>
                              <span v-if="!dd16.positive" class="negative-value">{{ dd16.percent }} %</span>
                              <span v-if="dd16.positive" class="primary--text">{{ dd16.percent }} %</span>
                              <span class="mr-1 ml-2">/ </span>
                              <span>{{ $t('overview.period') }}</span>
                              <v-tooltip bottom max-width="300px" color="primarydark">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon class="ml-2 greyxdark--text" small v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                                </template>
                                <span>{{ $t('overview.tooltip') }}</span>
                              </v-tooltip>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" xl="6" lg="6" md="12" sm="12" class="h-100 funnel-outer">
                    <v-card class="pa-4 rounded-lg v-card v-sheet theme--light elevation-3">
                      <vue-funnel-graph
                        v-if="currOverview && currOverview.grandTotal"
                        :width="width"
                        :height="height"
                        :labels="labels"
                        :values="values"
                        :colors="colors"
                        :sub-labels="subLabels"
                        :direction="direction"
                        :animated="false"
                        :display-percentage="false"
                        class="overflow-hidden"
                      ></vue-funnel-graph>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-bind:class="{ 'layout-3': showCL }" class="row-top-less graph-map-wrap">
                  <v-col cols="12" sm="12" md="6" class="data-graph">
                    <v-card class="px-4 py-6 rounded-lg h-100" elevation="3">
                      <template>
                        <v-card class="pa-0 dash-cs-tab" flat>
                          <v-toolbar flat dense prominent height="10">
                            <template v-slot:extension>
                              <v-tabs v-model="tabs">
                                <v-tab v-for="item in tabItems" :key="item" @click="chartChanged(item)">{{ item }}</v-tab>
                              </v-tabs>
                            </template>
                          </v-toolbar>

                          <v-tabs-items v-model="tabs">
                            <v-tab-item>
                              <v-card flat class="pa-0">
                                <v-card-title class="px-0">
                                  <v-row>
                                    <v-col cols="12" sm="6">
                                      <v-sheet color="white" elevation="0">
                                        <v-select
                                          class="rounded-lg"
                                          v-model="dd22.selected"
                                          :items="params"
                                          required
                                          outlined
                                          dense
                                          @change="changedd22"
                                          item-text="text"
                                          item-value="value"
                                          return-object
                                        ></v-select>
                                      </v-sheet>
                                    </v-col>
                                  </v-row>
                                </v-card-title>
                                <v-card-text class="px-0">
                                  <DeviceChart :height="220" :deviceData="deviceData" />
                                </v-card-text>
                              </v-card>
                            </v-tab-item>

                            <v-tab-item>
                              <v-card flat class="pa-0">
                                <v-card-title class="px-0">
                                  <v-row>
                                    <v-col cols="12" sm="6">
                                      <v-sheet color="white" elevation="0">
                                        <v-select
                                          class="no-field-details small-select rounded-lg"
                                          v-model="dd21.selected"
                                          :items="params"
                                          required
                                          outlined
                                          dense
                                          @change="changedd21"
                                          item-text="text"
                                          item-value="value"
                                          return-object
                                        ></v-select>
                                      </v-sheet>
                                    </v-col>
                                  </v-row>
                                </v-card-title>
                                <v-card-text class="px-0">
                                  <GenderChart :height="220" :genderData="genderData" />
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                          </v-tabs-items>
                        </v-card>
                      </template>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="12" md="6" class="data-map">
                    <v-card class="pa-4 rounded-lg extra-padding h-100" elevation="3">
                      <v-card-title class="pt-0 px-0">
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-sheet color="white" elevation="0">
                              <v-select
                                class="rounded-lg"
                                v-model="dd23.selected"
                                :items="params"
                                required
                                outlined
                                dense
                                @change="changedd23"
                                item-text="text"
                                item-value="value"
                                return-object
                              ></v-select>
                            </v-sheet>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text class="px-0">
                        <MapChart
                          :countryData="locationData"
                          highColor="#365ca8"
                          lowColor="#cce4e6"
                          countryStrokeColor="#909090"
                          defaultCountryFillColor="#dadada"
                          :currencyAdd="false"
                          :positionLeftTooltip="-230"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-card class="pa-4 rounded-lg extra-padding common-header-table h-100" elevation="3">
                      <v-card-title class="subtitle-1 pt-0 font-weight-medium px-0 justify-center">
                        <p class="common-para">{{ $t('overview.topKeywords') }}</p>
                        <v-sheet color="white" elevation="0" height="40" width="auto" max-width="150" class="ml-2">
                          <v-select
                            class="rounded-lg"
                            v-model="dd31.selected"
                            :items="paramsLimited"
                            required
                            outlined
                            dense
                            @change="changedd31"
                            item-text="text"
                            item-value="value"
                            return-object
                          ></v-select>
                        </v-sheet>
                      </v-card-title>

                      <v-card-text class="text-center pa-0">
                        <v-simple-table dense light class="table-style-3">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th id="Keyword" class="text-left">{{ $t('overview.key') }}</th>
                                <th id="Spend" class="text-left">{{ $t('overview.spend') }}</th>
                                <th id="Impressions" class="text-left">{{ $t('overview.impressions') }}</th>
                                <th id="Taps" class="text-left">{{ $t('overview.taps') }}</th>
                                <th id="Count" class="text-left">{{ $t('overview.count') }}</th>
                              </tr>
                            </thead>
                            <tbody v-if="topKeywords.length > 0">
                              <tr v-for="item in topKeywords" :key="item.id">
                                <td v-if="item" class="text-left">{{ item.name }}</td>
                                <td v-if="item" class="text-left">
                                  {{ getCurrencyIcon(item.currency) }} {{ numFormat(item.localSpend.toFixed(2)) }}
                                </td>
                                <td v-if="item" class="text-left">{{ numFormat(item.impressions) }}</td>
                                <td v-if="item" class="text-left">{{ numFormat(item.taps) }}</td>
                                <td v-if="item" class="text-left">{{ item.goalEventCount }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card-text>

                      <v-card-actions>
                        <v-btn depressed color="primary" @click="goToDashBoard('keywords')">
                          {{ $t('overview.goToKey') }}
                          <v-icon small>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>

                  <v-col cols="12" sm="12" md="6">
                    <v-card class="pa-4 rounded-lg common-header-table h-100" elevation="3" height="100%">
                      <v-card-title class="subtitle-1 font-weight-medium px-0 justify-center pt-0">
                        <p class="common-para">{{ $t('overview.topAdgroup') }}</p>
                        <v-sheet color="white" elevation="0" height="40" width="auto" max-width="150" class="ml-2">
                          <v-select
                            class="rounded-lg"
                            v-model="dd32.selected"
                            :items="paramsLimited"
                            required
                            outlined
                            dense
                            @change="changedd32"
                            item-text="text"
                            item-value="value"
                            return-object
                          ></v-select>
                        </v-sheet>
                      </v-card-title>

                      <v-card-text class="text-center pa-0">
                        <v-simple-table dense class="table-style-3">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th id="AdGroup" class="text-left">{{ $t('overview.adgroup') }}</th>
                                <th id="Spend" class="text-left">{{ $t('overview.spend') }}</th>
                                <th id="Impressions" class="text-left">{{ $t('overview.impressions') }}</th>
                                <th id="Taps" class="text-left">{{ $t('overview.taps') }}</th>
                                <th id="Count" class="text-left">{{ $t('overview.count') }}</th>
                              </tr>
                            </thead>
                            <tbody v-if="topAdgroups.length > 0">
                              <tr v-for="item in topAdgroups" :key="item.id">
                                <td v-if="item" class="text-left">{{ item.name }}</td>
                                <td v-if="item" class="text-left">
                                  {{ getCurrencyIcon(item.currency) }} {{ numFormat(item.localSpend.toFixed(2)) }}
                                </td>
                                <td v-if="item" class="text-left">{{ numFormat(item.impressions) }}</td>
                                <td v-if="item" class="text-left">{{ numFormat(item.taps) }}</td>
                                <td v-if="item" class="text-left">{{ numFormat(item.goalEventCount) }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card-text>

                      <v-card-actions>
                        <v-btn depressed color="primary" @click="goToDashBoard('adgroups')">
                          {{ $t('overview.goToAdgroup') }}
                          <v-icon small>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </div>

              <div class="col-right-main" v-if="showCL">
                <v-card class="mx-auto" max-width="466" elevation="1">
                  <v-card-title>
                    {{ $t('checklist.title') }}
                    <v-btn elevation="0" icon @click="showCL = false">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" fill="black" />
                      </svg>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <div class="titleText">
                      <v-icon :color="color0" class="mr-2" v-text="check0"></v-icon>
                      <a href="/integrations">{{ $t('checklist.integrations') }}</a>
                    </div>
                    <v-list-item :lang="locale" class="cust-list">
                      <v-list-item-content>
                        <v-icon size="15" :color="color1" class="mr-2" v-text="check1"></v-icon>
                        <a href="/integrations">{{ $t('checklist.searchads') }}</a>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :lang="locale" class="cust-list">
                      <v-list-item-content>
                        <v-icon size="15" :color="color6" class="mr-2" v-text="check6"></v-icon>
                        <a href="/integrations">{{ $t('checklist.slack') }}</a>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :lang="locale" class="cust-list">
                      <v-list-item-content>
                        <v-icon size="15" :color="mmpIntColor" class="mr-2" v-text="mmpIntCheck"></v-icon>
                        <a href="/integrations">{{ $t('checklist.mmpIntegration') }}</a>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="my-2"></v-divider>
                    <div class="titleText">
                      <v-icon :color="color11" class="mr-2" v-text="check11"></v-icon>
                      <a href="/keyword-auction-insights">{{ $t('checklist.kai') }}</a>
                    </div>
                    <v-list-item :lang="locale" class="cust-list">
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color2" size="15" v-text="check2"></v-icon>
                        <a href="/keyword-auction-insights?tab=comp">{{ $t('checklist.competitorMsg') }}</a>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color8" size="15" v-text="check8"></v-icon>
                        <a href="/keyword-auction-insights?tab=app">{{ $t('checklist.searchKeywords') }}</a>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color9" size="15" v-text="check9"></v-icon>
                        <a href="/keyword-auction-insights?tab=key">{{ $t('checklist.seeApps') }}</a>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color10" size="15" v-text="check10"></v-icon>
                        <a href="/keyword-auction-insights?tab=top">{{ $t('checklist.topCharts') }}</a>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="my-2"></v-divider>
                    <div class="titleText">
                      <v-icon class="mr-2" :color="color16" v-text="check16"></v-icon>
                      <a href="/create-campaign">{{ $t('checklist.campaign') }}</a>
                    </div>
                    <v-list-item :lang="locale" class="cust-list">
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color12" size="15" v-text="check12"></v-icon>
                        <a href="/create-campaign">{{ $t('checklist.initCampaign') }}</a>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color13" size="15" v-text="check13"></v-icon>
                        <a href="/create-campaign">{{ $t('checklist.createAdgroup') }}</a>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color14" size="15" v-text="check14"></v-icon>
                        <a href="/create-campaign">{{ $t('checklist.addKeywords') }}</a>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color15" size="15" v-text="check15"></v-icon>
                        <a href="/create-campaign">{{ $t('checklist.addCreativeSet') }}</a>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-icon class="mr-2" :color="color3" size="15" v-text="check3"></v-icon>
                        <a href="/ads-manager">{{ $t('checklist.goLive') }}</a>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="my-2"></v-divider>
                    <div class="titleText">
                      <v-icon class="mr-2" :color="color5" v-text="check5"></v-icon>
                      <a href="/overview">{{ $t('checklist.reviewMetrics') }}</a>
                    </div>
                    <v-divider class="my-2"></v-divider>
                    <div class="titleText">
                      <v-icon class="mr-2" :color="campGenColor" v-text="campGenCheck"></v-icon>
                      <a href="/campaign-generator">{{ $t('checklist.campGen') }}</a>
                    </div>
                    <v-divider class="my-2"></v-divider>
                    <div class="titleText">
                      <v-icon class="mr-2" :color="color4" v-text="check4"></v-icon>
                      <a href="/arrules">{{ $t('checklist.automate') }}</a>
                    </div>
                    <v-divider class="my-2"></v-divider>
                    <div class="titleText">
                      <v-icon class="mr-2" :color="scheduledReportsColor" v-text="scheduledReportsCheck"></v-icon>
                      <a href="/schedule-reports">{{ $t('checklist.scheduledReports') }}</a>
                    </div>
                    <v-divider class="my-2"></v-divider>
                    <div class="titleText pb-4">
                      <v-icon class="mr-2" :color="createMmpGoalsColor" v-text="createMmpGoalsCheck"></v-icon>
                      <a href="/goals">{{ $t('checklist.createMmpGoals') }}</a>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </template>
        </v-container>
      </div>
      <DataRequests :openDR="triggerDR"></DataRequests>
      <TaskDialog :openDR="tDR"></TaskDialog>
    </v-app>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  APP_OVERVIEW,
  APP_OVERVIEW_ALONE,
  APP_OVERVIEW_MAP,
  APP_OVERVIEW_DEVICE,
  TOP_ADS,
  TOP_KEYS,
  CHECK_APP_ATTR,
} from '../utils/apptweakqueries';
import { GET_APP_EVENTS_LIST, GET_APP_EVENTS_DATA, GET_GOALS, EXPORT_REPORT } from '../utils/apollo-queries';
import { SAVE_OVERVIEW_PRESETS, SAVE_LOGIN_ACTIVITY } from '../utils/apollo-mutations';
import moment from 'moment';
import GenderChart from '../components/overview/GenderChart';
import DeviceChart from '../components/overview/DeviceChart';
import MapChart from 'vue-chart-map';
import { LSS } from '@core/services';
import { decodeToken } from '@shared/utils/commom.util';
import { updateCLF, getOwnApps, numberFormat } from '../utils/util';
import { Kochava } from 'kochava';
import { SubscriptionPermissions } from '@shared/enums';
import DataRequests from '../components/DataRequests.vue';
import TaskDialog from '../components/TaskDialog.vue';
import { VueFunnelGraph } from 'vue-funnel-graph-js';
import getSymbolFromCurrency from 'currency-symbol-map';
export default Vue.extend({
  name: 'Overview',
  components: {
    GenderChart,
    DeviceChart,
    MapChart,
    DataRequests,
    VueFunnelGraph,
    TaskDialog,
  },
  props: {
    kochava: Kochava,
  },
  created() {
  },
  data() {
    const today = moment().utc().format('YYYY-MM-DD');

    return {
      maxDate: new Date().toISOString().split('T')[0],
      filterDateRangeModel: [
        LSS.dateRange && LSS.dateRange[0]
          ? moment(LSS.dateRange[0]).format('YYYY-MM-DD')
          : moment().utc().subtract(6, 'days').format('YYYY-MM-DD'),
        LSS.dateRange && LSS.dateRange[1] ? moment(LSS.dateRange[1]).format('YYYY-MM-DD') : today,
      ],
      filterDateRange: [
        LSS.dateRange && LSS.dateRange[0]
          ? moment(LSS.dateRange[0]).format('YYYY-MM-DD')
          : moment().utc().subtract(6, 'days').format('YYYY-MM-DD'),
        LSS.dateRange && LSS.dateRange[1] ? moment(LSS.dateRange[1]).format('YYYY-MM-DD') : today,
      ],
      rangePicker: false,
      mobileRangePickerDialog: false,
      currentDate: LSS.dateRange && LSS.dateRange[1] ? new Date(moment(LSS.dateRange[1]).format('YYYY-MM-DD')) : new Date(today),
      previousDate:
        LSS.dateRange && LSS.dateRange[0]
          ? new Date(moment(LSS.dateRange[0]).format('YYYY-MM-DD'))
          : new Date(moment().utc().subtract(7, 'days').format('YYYY-MM-DD')),
      filterDateCount:
        LSS.dateRange && LSS.dateRange[1] && LSS.dateRange[0]
          ? (new Date(moment(LSS.dateRange[1]).format('YYYY-MM-DD')) - new Date(moment(LSS.dateRange[0]).format('YYYY-MM-DD'))) /
            (1000 * 60 * 60 * 24)
          : 6,
      subLabels: [],
      presetStatus: false,
      updatedDaysText: '',
      colors: ['#6682AA'],
      direction: 'horizontal',
      gradientDirection: 'horizontal',
      height: 292,
      width: 800,
      triggerDR: null,
      tDR: null,
      drProgress: 0,
      SubscriptionPermissionsEnums: SubscriptionPermissions,
      syncStatus: false,
      dialog: true,
      locale: 'en',
      selectedGoal: { _id: '0', name: 'Select an App', appId: 0, eventId: '', status: '', type: '' },
      appGoals: [{ _id: '0', name: 'Select Goals', appId: 0, eventId: '', status: '', type: '' }],
      loadingGoals: false,
      disableGoalDD: true,
      genData: {},
      existAttr: false,
      existCL: false,
      checkedIcon: 'mdi-check-circle',
      uncheckedIcon: 'mdi-checkbox-blank-circle-outline',
      check0: 'mdi-checkbox-blank-circle-outline',
      check1: 'mdi-checkbox-blank-circle-outline',
      check2: 'mdi-checkbox-blank-circle-outline',
      check3: 'mdi-checkbox-blank-circle-outline',
      check4: 'mdi-checkbox-blank-circle-outline',
      check5: 'mdi-checkbox-blank-circle-outline',
      check6: 'mdi-checkbox-blank-circle-outline',
      check7: 'mdi-checkbox-blank-circle-outline',
      check8: 'mdi-checkbox-blank-circle-outline',
      check9: 'mdi-checkbox-blank-circle-outline',
      check10: 'mdi-checkbox-blank-circle-outline',
      check11: 'mdi-checkbox-blank-circle-outline',
      check12: 'mdi-checkbox-blank-circle-outline',
      check13: 'mdi-checkbox-blank-circle-outline',
      check14: 'mdi-checkbox-blank-circle-outline',
      check15: 'mdi-checkbox-blank-circle-outline',
      check16: 'mdi-checkbox-blank-circle-outline',
      mmpIntCheck: 'mdi-checkbox-blank-circle-outline',
      createMmpGoalsCheck: 'mdi-checkbox-blank-circle-outline',
      scheduledReportsCheck: 'mdi-checkbox-blank-circle-outline',
      campGenCheck: 'mdi-checkbox-blank-circle-outline',
      color0: '',
      color1: '',
      color7: '',
      color2: '',
      color6: '',
      color3: '',
      color4: '',
      color5: '',
      color8: '',
      color9: '',
      color10: '',
      color11: '',
      color12: '',
      color13: '',
      color14: '',
      color15: '',
      color16: '',
      mmpIntColor: '',
      createMmpGoalsColor: '',
      scheduledReportsColor: '',
      campGenColor: '',
      showCL: false,
      mapData: {},
      genderData: {},
      deviceData: {},
      locationData: {},
      tabs: null,
      text:
        'Lorem ipsum dolor sit amet, consectetur. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',

      drawer: false,
      mini: true,
      e1: 1,
      dd11: {
        selected: {
          text: this.$t('overview.impressions'),
          value: 'impressions',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd12: {
        selected: {
          text: this.$t('overview.taps'),
          value: 'taps',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd13: {
        selected: {
          text: this.$t('overview.installs'),
          value: 'installs',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd14: {
        selected: {
          text: this.$t('overview.spend'),
          value: 'localSpend',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd15: {
        selected: {
          text: this.$t('overview.avgCPA'),
          value: 'avgCPA',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd16: {
        selected: {
          text: this.$t('overview.avgCPT'),
          value: 'avgCPT',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd21: {
        selected: {
          text: this.$t('overview.impressions'),
          value: 'impressions',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd22: {
        selected: {
          text: this.$t('overview.impressions'),
          value: 'impressions',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd23: {
        selected: {
          text: this.$t('overview.impressions'),
          value: 'impressions',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd31: {
        selected: {
          text: this.$t('overview.spend'),
          value: 'localSpend',
        },
        value: 0,
        percent: 0,
        positive: true,
      },
      dd32: {
        selected: {
          text: this.$t('overview.spend'),
          value: 'localSpend',
        },
        value: 0,
        percent: 0,
        positive: true,
      },

      topAdgroups: [],
      topKeywords: [],

      loading: true,
      modal: false,
      items: [],
      menuitems: [],
      search: '',
      loadingApps: false,
      searchedApps: [],
      selectedApp: '',
      currOverview: null,
      prevOverview: null,
      tempApps: [],
      fullscreenLoader: null,
      fullscreenLoaderAlone: null,
      fullscreenLoaderGender: null,
      fullscreenLoaderDevice: null,
      fullscreenLoaderMap: null,
      paramsDD11Temp: [],
      paramsDD12Temp: [],
      paramsDD13Temp: [],
      paramsDD14Temp: [],
      paramsDD15Temp: [],
      paramsDD16Temp: [],
      eventsData: [],
      revenueData: [],
      goalData: [],
      goalCount: 0,
      asaKeys: [
        'impressions',
        'taps',
        'installs',
        'localSpend',
        'ttr',
        'avgCPA',
        'avgCPT',
        'conversionRate',
        'latOnInstalls',
        'latOffInstalls',
        'redownloads',
        'newDownloads',
      ],
      mmpKeys: [
        'ATTI',
        'ATIR',
        'ATLI',
        'CPRI',
        'GOLS',
        'CPRG',
        'RPRG',
        'GREV',
        'GRAS',
        'GETC',
        'ACNV',
        'CPRC',
        'ACNR',
        'RPRC',
        'ARPU',
        'RVNU',
        'ROAS',
        'AMPU',
      ],
      currMMP: null,
      prevMMP: null,
      goalRevenueCurr: 0,
      gecCurr: 0,
      goalRevenuePrev: 0,
      gecPrev: 0,
      databrowser: [
        { name: 'Edge', value: 'Edg', version: 'Edg' },
        { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
        { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
        { name: 'Safari', value: 'Safari', version: 'Version' },
        { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
        { name: 'Opera', value: 'Opera', version: 'Opera' },
        { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
        { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
      ],
      browserHeader: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],

      goalsList: [],

      selectAllIcon: 'mdi-crop-square',
      exportReportKey: 0,
      exportReportModel: false,
      selectedDatasets: [
        { text: 'Date', value: 'date', appId: null, disabled: true },
        { text: 'Account', value: 'metadata.orgId', appId: null, disabled: true },
        { text: 'App ID', value: 'metadata.app.adamId', appId: null, disabled: true },
        { text: 'Campaign ID', value: 'metadata.campaignId', appId: null, disabled: true },
        { text: 'Campaign Status', value: 'metadata.campaignStatus', appId: null, disabled: true },
        { text: 'Ad Group ID', value: 'metadata.adGroupId', appId: null, disabled: true },
        { text: 'Ad Group Status', value: 'metadata.adGroupStatus', appId: null, disabled: true },
        { text: 'Keyword', value: 'metadata.keyword', appId: null, disabled: true },
        { text: 'Keyword Id', value: 'metadata.keywordId', appId: null, disabled: true },
        { text: 'Search Term Text', value: 'metadata.searchTermText', appId: null, disabled: true },
        { text: 'Bid Amount', value: 'metadata.bidAmount.amount', appId: null, disabled: true },
        { text: 'ASA Suggested Max Bid', value: 'insights.bidRecommendation.bidMin.amount', appId: null, disabled: true },
        { text: 'ASA Suggested Min Bid', value: 'insights.bidRecommendation.bidMax.amount', appId: null, disabled: true },
        { text: 'Ad', value: 'metadata.adId', appId: null, disabled: true },
        { text: 'Creative Type', value: 'metadata.creativeType', appId: null, disabled: true },
        { text: 'Impressions', value: 'total.impressions', appId: null, disabled: true },
        { text: 'Taps', value: 'total.taps', appId: null, disabled: true },
        { text: 'Installs', value: 'total.installs', appId: null, disabled: true },
        { text: 'Spend', value: 'total.localSpend.amount', appId: null, disabled: true },
        { text: 'New Downloads', value: 'total.newDownloads', appId: null, disabled: true },
        { text: 'Re-Downloads', value: 'total.redownloads', appId: null, disabled: true },
        { text: 'Average CPA', value: 'total.avgCPA.amount', appId: null, disabled: true },
        { text: 'Average CPT', value: 'total.avgCPT.amount', appId: null, disabled: true },
        { text: 'Delete', value: 'metadata.deleted', appId: null, disabled: true },
        { text: 'TTR', value: 'total.ttr', appId: null, disabled: true },
        { text: 'Conversion Rate', value: 'total.conversionRate', appId: null, disabled: true },
        { text: 'LAT Off Installs', value: 'total.latOffInstalls', appId: null, disabled: true },
        { text: 'LAT On Installs', value: 'total.latOnInstalls', appId: null, disabled: true },
        { text: 'Storefronts', value: 'metadata.countriesOrRegions', appId: null, disabled: true },
      ],
      reportDatasets: [
        { text: 'Date', value: 'date', appId: null, disabled: true },
        { text: 'Account', value: 'metadata.orgId', appId: null, disabled: true },
        { text: 'App ID', value: 'metadata.app.adamId', appId: null, disabled: true },
        { text: 'Campaign ID', value: 'metadata.campaignId', appId: null, disabled: true },
        { text: 'Campaign Status', value: 'metadata.campaignStatus', appId: null, disabled: true },
        { text: 'Ad Group ID', value: 'metadata.adGroupId', appId: null, disabled: true },
        { text: 'Ad Group Status', value: 'metadata.adGroupStatus', appId: null, disabled: true },
        { text: 'Keyword', value: 'metadata.keyword', appId: null, disabled: true },
        { text: 'Keyword Id', value: 'metadata.keywordId', appId: null, disabled: true },
        { text: 'Search Term Text', value: 'metadata.searchTermText', appId: null, disabled: true },
        { text: 'Bid Amount', value: 'metadata.bidAmount.amount', appId: null, disabled: true },
        { text: 'ASA Suggested Max Bid', value: 'insights.bidRecommendation.bidMin.amount', appId: null, disabled: true },
        { text: 'ASA Suggested Min Bid', value: 'insights.bidRecommendation.bidMax.amount', appId: null, disabled: true },
        { text: 'Ad', value: 'metadata.adId', appId: null, disabled: true },
        { text: 'Creative Type', value: 'metadata.creativeType', appId: null, disabled: true },
        { text: 'Impressions', value: 'total.impressions', appId: null, disabled: true },
        { text: 'Taps', value: 'total.taps', appId: null, disabled: true },
        { text: 'Installs', value: 'total.installs', appId: null, disabled: true },
        { text: 'Spend', value: 'total.localSpend.amount', appId: null, disabled: true },
        { text: 'New Downloads', value: 'total.newDownloads', appId: null, disabled: true },
        { text: 'Re-Downloads', value: 'total.redownloads', appId: null, disabled: true },
        { text: 'Average CPA', value: 'total.avgCPA.amount', appId: null, disabled: true },
        { text: 'Average CPT', value: 'total.avgCPT.amount', appId: null, disabled: true },
        { text: 'Delete', value: 'metadata.deleted', appId: null, disabled: true },
        { text: 'TTR', value: 'total.ttr', appId: null, disabled: true },
        { text: 'Conversion Rate', value: 'total.conversionRate', appId: null, disabled: true },
        { text: 'LAT Off Installs', value: 'total.latOffInstalls', appId: null, disabled: true },
        { text: 'LAT On Installs', value: 'total.latOnInstalls', appId: null, disabled: true },
        { text: 'Storefronts', value: 'metadata.countriesOrRegions', appId: null, disabled: true },
      ],
      receiverEmail: null,
    };
  },
  computed: {
    filterDateRangeText() {
      if (this.filterDateRange[0] > this.filterDateRange[1]) {
        const maxDate = this.filterDateRange[0];
        this.filterDateRange[0] = this.filterDateRange[1];
        this.filterDateRange[1] = maxDate;
      }
      return this.filterDateRange.join(' - ');
    },
    labels: {
      get() {
        return [this.$t('overview.impressions'), this.$t('overview.taps'), this.$t('overview.installs')];
      },
    },
    values: {
      get() {
        return [
          this.currOverview?.grandTotal?.impressions,
          this.currOverview?.grandTotal?.taps,
          this.currOverview?.grandTotal?.installs,
        ].map((v) => numberFormat(v, true));
      },
    },
    permissions: {
      cache: false,
      get() {
        if (decodeToken(LSS.token)) {
          if (decodeToken(LSS.token).Role == 'SUPERADMIN') {
            return [];
          } else {
            return decodeToken(LSS.token).permissions ? decodeToken(LSS.token).permissions : [];
          }
        }
      },
    },
    isSuperAdmin: {
      cache: false,
      get() {
        if (decodeToken(LSS.token) && decodeToken(LSS.token).Role == 'SUPERADMIN') {
          return true;
        }
        return false;
      },
    },
    tabItems: {
      get() {
        return [this.$t('overview.device'), this.$t('overview.gender')];
      },
    },
    apps: {
      get() {
        return [this.$t('overview.searchApp')];
      },
    },
    days: {
      get() {
        return [this.$t('overview.lastDay'), this.$t('overview.last7Days'), this.$t('overview.last30Days')];
      },
    },
    KeywordsBy: {
      get() {
        return [this.$t('overview.spend'), this.$t('overview.impressions'), this.$t('overview.taps')];
      },
    },
    adGroupsBy: {
      get() {
        return [this.$t('overview.spend'), this.$t('overview.impressions'), this.$t('overview.taps')];
      },
    },
    paramsLimited: {
      get() {
        return [
          {
            text: this.$t('overview.impressions'),
            value: 'impressions',
          },
          {
            text: this.$t('overview.taps'),
            value: 'taps',
          },
          {
            text: this.$t('overview.spend'),
            value: 'localSpend',
          },
          {
            text: this.$t('overview.count'),
            value: 'goalEventCount',
            disabled: !this.existAttr,
          },
        ];
      },
      set(e) {
        this.paramsLimited.text = e.text;
        this.paramsLimited.value = e.value;
      },
    },
    paramsFull: {
      get() {
        return [
          {
            header: 'ASA Fields',
            divider: true,
          },
          {
            text: this.$t('overview.impressions'),
            value: 'impressions',
          },
          {
            text: this.$t('overview.taps'),
            value: 'taps',
          },
          {
            text: this.$t('overview.installs'),
            value: 'installs',
          },
          {
            text: this.$t('overview.spend'),
            value: 'localSpend',
          },
          {
            text: this.$t('overview.ttr'),
            value: 'ttr',
          },
          {
            text: this.$t('overview.avgCPA'),
            value: 'avgCPA',
          },
          {
            text: this.$t('overview.avgCPT'),
            value: 'avgCPT',
          },
          {
            text: this.$t('overview.conversionRate'),
            value: 'conversionRate',
          },
          {
            text: this.$t('overview.latOnInstalls'),
            value: 'latOnInstalls',
          },
          {
            text: this.$t('overview.latOffInstalls'),
            value: 'latOffInstalls',
          },
          {
            text: this.$t('overview.reDownloads'),
            value: 'redownloads',
          },
          {
            text: this.$t('overview.newDownloads'),
            value: 'newDownloads',
          },
          {
            header: 'Attribution Fields',
            divider: true,
          },
          {
            text: this.$t('overview.GETC'),
            value: 'GETC',
            disabled: !this.existAttr,
          },
          {
            text: this.$t('overview.CPRG'),
            value: 'CPRG',
            disabled: !this.existAttr,
          },
          {
            text: this.$t('overview.RPRG'),
            value: 'RPRG',
            disabled: !this.existAttr,
          },
          {
            text: this.$t('overview.GREV'),
            value: 'GREV',
            disabled: !this.existAttr,
          },
          {
            text: this.$t('overview.GRAS'),
            value: 'GRAS',
            disabled: !this.existAttr,
          },
          {
            text: this.$t('overview.ARPU'),
            value: 'ARPU',
            disabled: !this.existAttr,
          },
          {
            text: this.$t('overview.AMPU'),
            value: 'AMPU',
            disabled: !this.existAttr,
          },
        ];
      },
    },
    params: {
      get() {
        return [
          {
            text: this.$t('overview.impressions'),
            value: 'impressions',
          },
          {
            text: this.$t('overview.taps'),
            value: 'taps',
          },
          {
            text: this.$t('overview.ttr'),
            value: 'ttr',
          },
          {
            text: this.$t('overview.spend'),
            value: 'localSpend',
          },
          {
            text: this.$t('overview.installs'),
            value: 'installs',
          },
          {
            text: this.$t('overview.avgCPA'),
            value: 'avgCPA',
          },
          {
            text: this.$t('overview.avgCPT'),
            value: 'avgCPT',
          },
          {
            text: this.$t('overview.conversionRate'),
            value: 'conversionRate',
          },
          {
            text: this.$t('overview.latOnInstalls'),
            value: 'latOnInstalls',
          },
          {
            text: this.$t('overview.latOffInstalls'),
            value: 'latOffInstalls',
          },
          {
            text: this.$t('overview.reDownloads'),
            value: 'redownloads',
          },
          {
            text: this.$t('overview.newDownloads'),
            value: 'newDownloads',
          },
        ];
      },
    },
    paramsDD11: {
      get() {
        return this.paramsDD11Temp;
      },
      set(e) {
        this.paramsDD11Temp = e;
      },
    },
    paramsDD12: {
      get() {
        return this.paramsDD12Temp;
      },
      set(e) {
        this.paramsDD12Temp = e;
      },
    },
    paramsDD13: {
      get() {
        return this.paramsDD13Temp;
      },
      set(e) {
        this.paramsDD13Temp = e;
      },
    },
    paramsDD14: {
      get() {
        return this.paramsDD14Temp;
      },
      set(e) {
        this.paramsDD14Temp = e;
      },
    },
    paramsDD15: {
      get() {
        return this.paramsDD15Temp;
      },
      set(e) {
        this.paramsDD15Temp = e;
      },
    },
    paramsDD16: {
      get() {
        return this.paramsDD16Temp;
      },
      set(e) {
        this.paramsDD16Temp = e;
      },
    },
    periods: {
      get() {
        return [
          {
            text: this.$t('overview.lastDay'),
            value: 1,
          },
          {
            text: this.$t('overview.last7Days'),
            value: 7,
          },
          {
            text: this.$t('overview.last30Days'),
            value: 30,
          },
        ];
      },
    },
    period: {
      get() {
        return {
          text: this.filterDateCount == 6 ? this.$t('overview.last7Days') : '',
          value: this.filterDateCount,
        };
      },
      set(e) {
        this.period.text = e.text;
        this.period.value = e.value;
      },
    },
    searchApp: {
      get() {
        return {
          adamId: 0,
          appName: this.$t('overview.all'),
        };
      },
      set(e) {
        this.searchApp.adamId = e.adamId;
        this.searchApp.appName = e.appName;
      },
    },
  },
  methods: {
    getCurrencyIcon(value) {
      if (value !== undefined || value !== null) {
        return getSymbolFromCurrency(value) == undefined ? '' : getSymbolFromCurrency(value);
      }
      return '';
    },
    getMaxDate() {
      // Function to get the maximum allowable date (today's date)
      return new Date().toISOString().substr(0, 10);
    },
    getGoals() {
      this.$apollo
        .query({
          query: GET_GOALS,
        })
        .then((res) => {
          this.goalsList = res.data.getGoals;
          this.eventStringForReport(res.data.getGoals);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    eventStringForReport(goalsList) {
      const attrExistAll = this.searchedApps.filter((e) => e.attrExist);
      const apps = attrExistAll.filter((e) => e.adamId != 0);
      const goalsForApp = [];
      for (const app of apps) {
        const goals = goalsList.filter((e) => e.appId == app.adamId);
        goalsForApp.push(...goals);
      }

      const goalDatasets = [];
      for (const goal of goalsForApp) {
        const eventNamesInGoal2 = new Set(goal.eventId);
        let enamesString = '';
        eventNamesInGoal2.forEach((e) => {
          enamesString = enamesString + '&event_name=' + encodeURIComponent(e);
        });
        goalDatasets.push({ text: goal.name, value: goal._id, appId: goal.appId, disabled: true });
      }
      const unqGoalDataSets = Array.from(new Set(goalDatasets));
      const RpDataSet = this.reportDatasets.slice(0, 29);
      const dataSets = RpDataSet.concat(unqGoalDataSets);
      this.reportDatasets = dataSets;
      this.selectedDatasets = this.reportDatasets;
      this.exportReportKey += 1;
    },

    showExportReportModel() {
      this.exportReportModel = true;
    },

    closeExportReportModel() {
      this.exportReportModel = false;
      this.receiverEmail = null;
    },

    toggleSelectAll() {
      if (this.selectedDatasets.length == this.reportDatasets.length) {
        this.selectedDatasets = '';
        this.selectAllIcon = 'mdi-crop-square';
      } else {
        this.selectedDatasets = this.reportDatasets;
        this.selectAllIcon = 'mdi-checkbox-multiple-marked';
      }
    },

    async exportReport() {
      for (const dataset of this.selectedDatasets) {
        delete dataset.disabled;
      }
      this.$apollo
        .query({
          query: EXPORT_REPORT,
          variables: {
            filter: {
              dateRange: this.filterDateRangeModel,
            },
            receiverMail: this.receiverEmail,
            reportDatasets: this.selectedDatasets,
          },
        })
        .then((res) => {
          if (res.data.exportReport.successMessage == 'OK') {
            this.$notify.success('Report will be received within 5min');
          } else {
            this.$notify.error('Report Failed to Send. Please try again or contact your Customer Service Manager.');
          }
        })
        .catch((error) => {
          this.$notify.error('Report Failed to Send. Please try again or contact your Customer Service Manager.');
          console.log(error);
        });

      for (const dataset of this.selectedDatasets) {
        dataset.disabled = true;
      }

      this.closeExportReportModel();
    },

    openDataRequests() {
      this.triggerDR = {
        now: Date.now(),
        items: null,
      };
    },
    openTaskRequests() {
      this.tDR = {
        now: Date.now(),
        items: null,
      };
    },

    clearDraftId() {
      LSS.clearOther();
    },

    popupGuided() {
      return this.$router.push('/ads-manager');
    },

    async getEventsListForApp() {
      const appId = this.searchApp.adamId;
      if (!appId) return;
      if (!this.fullscreenLoaderAlone) this.fullscreenLoaderAlone = this.$loading.show();
      const result2 = await this.$apollo
        .query({
          query: GET_APP_EVENTS_DATA,
          variables: {
            appId: appId,
            period: parseInt(this.period.value),
            at: Date.now(),
            currdate: this.currentDate,
            prevdate: this.previousDate,
            goal: this.selectedGoal._id,
          },
        })
        .catch((e) => {
          this.currMMP = null;
          this.prevMMP = null;
          this.fullscreenLoaderAlone.hide();
          return;
        });
      if (!result2.data.currMMPData.data || !result2.data.prevMMPData.data) {
        this.currMMP = null;
        this.prevMMP = null;
        this.fullscreenLoaderAlone.hide();
        return;
      }
      this.currMMP = JSON.parse(result2.data.currMMPData.data);
      this.prevMMP = JSON.parse(result2.data.prevMMPData.data);
      this.fullscreenLoaderAlone.hide();

      if (!Array.isArray(this.currMMP.eventsData)) this.currMMP.eventsData = [];
      if (!Array.isArray(this.currMMP.revenueData)) this.currMMP.revenueData = [];
      if (!Array.isArray(this.currMMP.goalData)) this.currMMP.goalData = [];

      if (!Array.isArray(this.prevMMP.eventsData)) this.prevMMP.eventsData = [];
      if (!Array.isArray(this.prevMMP.revenueData)) this.prevMMP.revenueData = [];
      if (!Array.isArray(this.prevMMP.goalData)) this.prevMMP.goalData = [];

      this.gecCurr = this.currMMP.eventsData.reduce((t, e) => t + +e.count, 0);
      this.goalRevenueCurr = this.currMMP.revenueData.reduce((t, e) => t + +e.total, 0);
      this.gecPrev = this.prevMMP.eventsData.reduce((t, e) => t + +e.count, 0);
      this.goalRevenuePrev = this.prevMMP.revenueData.reduce((t, e) => t + +e.total, 0);
      return;
    },
    initializeDD() {
      this.dd11 = {
        selected: {
          text: this.$t('overview.impressions'),
          value: 'impressions',
        },
        value: 0,
        percent: 0,
        positive: true,
      };
      this.dd12 = {
        selected: {
          text: this.$t('overview.taps'),
          value: 'taps',
        },
        value: 0,
        percent: 0,
        positive: true,
      };
      this.dd13 = {
        selected: {
          text: this.$t('overview.installs'),
          value: 'installs',
        },
        value: 0,
        percent: 0,
        positive: true,
      };
      this.dd14 = {
        selected: {
          text: this.$t('overview.spend'),
          value: 'localSpend',
        },
        value: 0,
        percent: 0,
        positive: true,
      };
      this.dd15 = {
        selected: {
          text: this.$t('overview.avgCPA'),
          value: 'avgCPA',
        },
        value: 0,
        percent: 0,
        positive: true,
      };
      this.dd16 = {
        selected: {
          text: this.$t('overview.avgCPT'),
          value: 'avgCPT',
        },
        value: 0,
        percent: 0,
        positive: true,
      };
      this.dd21 = {
        selected: {
          text: this.$t('overview.impressions'),
          value: 'impressions',
        },
        value: 0,
        percent: 0,
        positive: true,
      };
      this.dd22 = {
        selected: {
          text: this.$t('overview.impressions'),
          value: 'impressions',
        },
        value: 0,
        percent: 0,
        positive: true,
      };
      this.dd23 = {
        selected: {
          text: this.$t('overview.impressions'),
          value: 'impressions',
        },
        value: 0,
        percent: 0,
        positive: true,
      };
      this.dd31 = {
        selected: {
          text: this.$t('overview.spend'),
          value: 'localSpend',
        },
        value: 0,
        percent: 0,
        positive: true,
      };
      this.dd32 = {
        selected: {
          text: this.$t('overview.spend'),
          value: 'localSpend',
        },
        value: 0,
        percent: 0,
        positive: true,
      };
    },
    async changePeriod() {
      this.filterDateRange = this.filterDateRangeModel;
      this.filterDateRange.sort();
      this.currentDate = new Date(this.filterDateRange[1]);
      this.previousDate = new Date(this.filterDateRange[0]);
      const filterdate = this.currentDate - this.previousDate;
      this.filterDateCount = filterdate / (1000 * 60 * 60 * 24);
      this.rangePicker = false;
      if (this.existAttr) await this.getEventsListForApp();
      this.getOverview();
      this.getOverviewDevice();
      this.getOverviewAlone();
      this.getOverviewMap();
      this.getTopAdGroups();
      this.getTopKeywords();
      this.mobileRangePickerDialog = false;
      if (this.presetStatus == false) {
        this.period.text = this.updatedDaysText = '';
      } else {
        this.period.text = this.updatedDaysText;
        this.presetStatus = false;
      }
    },
    async changeApp(e) {
      this.existAttr = e.attrExist;
      LSS.attr = e.attrExist;
      if (e.attrExist) await this.getEventsListForApp();
      else {
        this.initializeDD();
        LSS.overviewDD = {
          dd11: {
            text: 'Impressions',
            value: 'impressions',
          },
          dd12: {
            text: 'Taps',
            value: 'taps',
          },
          dd13: {
            text: 'Installs',
            value: 'installs',
          },
          dd14: {
            text: 'Spend',
            value: 'localSpend',
          },
          dd15: {
            text: 'Average CPA',
            value: 'avgCPA',
          },
          dd16: {
            text: 'Average CPT',
            value: 'avgCPT',
          },
          dd21: {
            text: 'Impressions',
            value: 'impressions',
          },
          dd22: {
            text: 'Impressions',
            value: 'impressions',
          },
          dd23: {
            text: 'Impressions',
            value: 'impressions',
          },
          dd31: {
            text: 'Spend',
            value: 'localSpend',
          },
          dd32: {
            text: 'Spend',
            value: 'localSpend',
          },
        };
      }
      this.repopulateDDNew();
      this.getOverview();
      this.getOverviewDevice();
      this.getOverviewAlone();
      this.getOverviewMap();
      this.getTopAdGroups();
      this.getTopKeywords();
      this.filterGoalListForApp();
    },
    filterGoalListForApp() {
      const appId = this.searchApp.adamId;
      this.appGoals = this.goalsList.filter((e) => e.appId == appId);
      if (this.existAttr && this.appGoals.length > 0) {
        this.disableGoalDD = false;
        this.appGoals.unshift({ _id: '0', name: 'All', appId: 0, eventId: '', status: '', type: '' });
        this.selectedGoal = { _id: '0', name: 'All', appId: 0, eventId: '', status: '', type: '' };
      } else {
        this.disableGoalDD = true;
        this.appGoals = [{ _id: '0', name: 'No Goals', appId: 0, eventId: '', status: '', type: '' }];
        this.selectedGoal = { _id: '0', name: 'No Goals', appId: 0, eventId: '', status: '', type: '' };
        if (appId == 0) {
          this.appGoals = [{ _id: '0', name: 'Select an App', appId: 0, eventId: '', status: '', type: '' }];
          this.selectedGoal = { _id: '0', name: 'Select an App', appId: 0, eventId: '', status: '', type: '' };
        }
      }
    },
    selectPreset(preset) {
      this.presetStatus = true;
      this.updatedDaysText = '';
      switch (preset) {
        case 'TODAY':
          this.filterDateRangeModel = [moment().utc().format('YYYY-MM-DD'), moment().utc().format('YYYY-MM-DD')];
          this.updatedDaysText = this.$t('dateRangePresets.today');
          break;
        case 'YESTERDAY':
          this.filterDateRangeModel = [
            moment().utc().subtract(1, 'days').format('YYYY-MM-DD'),
            moment().utc().subtract(1, 'days').format('YYYY-MM-DD'),
          ];
          this.updatedDaysText = this.$t('dateRangePresets.yesterday');
          break;
        case 'WEEKTODATE':
          this.filterDateRangeModel = [moment().utc().startOf('week').format('YYYY-MM-DD'), moment().utc().format('YYYY-MM-DD')];
          this.updatedDaysText = this.$t('dateRangePresets.weekToDate');
          break;
        case 'LASTWEEK':
          this.filterDateRangeModel = [
            moment().utc().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
            moment().utc().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'),
          ];
          this.updatedDaysText = this.$t('dateRangePresets.lastWeek');
          break;
        case 'LASTSEVENDAYS':
          this.filterDateRangeModel = [moment().utc().subtract(6, 'days').format('YYYY-MM-DD'), moment().utc().format('YYYY-MM-DD')];
          this.updatedDaysText = this.$t('dateRangePresets.lastSevenDays');
          break;
        case 'MONTHTODATE':
          this.filterDateRangeModel = [moment().utc().startOf('month').format('YYYY-MM-DD'), moment().utc().format('YYYY-MM-DD')];
          this.updatedDaysText = this.$t('dateRangePresets.monthToDate');
          break;
        case 'LASTMONTH':
          this.filterDateRangeModel = [
            moment().utc().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            moment().utc().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
          ];
          this.updatedDaysText = this.$t('dateRangePresets.lastMonth');
          break;
        case 'LASTTHIRTYDAY':
          this.filterDateRangeModel = [moment().utc().subtract(29, 'days').format('YYYY-MM-DD'), moment().utc().format('YYYY-MM-DD')];
          this.updatedDaysText = this.$t('dateRangePresets.lastThirtyDays');
          break;
      }
    },
    changedd11(e) {
      this.bindData('dd11', this.dd11.selected.value);
      this.repopulateDDNew();
      this.saveLSS();
    },
    changedd12() {
      this.bindData('dd12', this.dd12.selected.value);
      this.repopulateDDNew();
      this.saveLSS();
    },
    changedd13() {
      this.bindData('dd13', this.dd13.selected.value);
      this.repopulateDDNew();
      this.saveLSS();
    },
    changedd14() {
      this.bindData('dd14', this.dd14.selected.value);
      this.repopulateDDNew();
      this.saveLSS();
    },
    changedd15() {
      this.bindData('dd15', this.dd15.selected.value);
      this.repopulateDDNew();
      this.saveLSS();
    },
    changedd16() {
      this.bindData('dd16', this.dd16.selected.value);
      this.repopulateDDNew();
      this.saveLSS();
    },
    repopulateDD() {
      this.paramsDD11 = [];
      this.paramsDD11.push(this.dd11.selected);
      for (let index = 0; index < this.paramsFull.length; index++) {
        const element = this.paramsFull[index];
        if (
          element.value === this.dd12.selected.value ||
          element.value === this.dd13.selected.value ||
          element.value === this.dd14.selected.value
        ) {
          continue;
        }
        this.paramsDD11.push(element);
      }
      this.paramsDD12 = [];
      this.paramsDD12.push(this.dd12.selected);
      for (let index = 0; index < this.paramsFull.length; index++) {
        const element = this.paramsFull[index];
        if (
          element.value === this.dd11.selected.value ||
          element.value === this.dd13.selected.value ||
          element.value === this.dd14.selected.value
        ) {
          continue;
        }
        this.paramsDD12.push(element);
      }
      this.paramsDD13 = [];
      this.paramsDD13.push(this.dd13.selected);
      for (let index = 0; index < this.paramsFull.length; index++) {
        const element = this.paramsFull[index];
        if (
          element.value === this.dd11.selected.value ||
          element.value === this.dd12.selected.value ||
          element.value === this.dd14.selected.value
        ) {
          continue;
        }
        this.paramsDD13.push(element);
      }
      this.paramsDD14 = [];
      this.paramsDD14.push(this.dd14.selected);
      for (let index = 0; index < this.paramsFull.length; index++) {
        const element = this.paramsFull[index];
        if (
          element.value === this.dd11.selected.value ||
          element.value === this.dd12.selected.value ||
          element.value === this.dd13.selected.value
        ) {
          continue;
        }
        this.paramsDD14.push(element);
      }
    },
    repopulateDDNew() {
      this.paramsDD11 = this.paramsFull.filter(
        (e) =>
          e.value !== this.dd14.selected.value &&
          e.value !== this.dd12.selected.value &&
          e.value !== this.dd15.selected.value &&
          e.value !== this.dd16.selected.value &&
          e.value !== this.dd13.selected.value
      );
      this.paramsDD12 = this.paramsFull.filter(
        (e) =>
          e.value !== this.dd11.selected.value &&
          e.value !== this.dd14.selected.value &&
          e.value !== this.dd15.selected.value &&
          e.value !== this.dd16.selected.value &&
          e.value !== this.dd13.selected.value
      );
      this.paramsDD13 = this.paramsFull.filter(
        (e) =>
          e.value !== this.dd11.selected.value &&
          e.value !== this.dd12.selected.value &&
          e.value !== this.dd15.selected.value &&
          e.value !== this.dd16.selected.value &&
          e.value !== this.dd14.selected.value
      );
      this.paramsDD14 = this.paramsFull.filter(
        (e) =>
          e.value !== this.dd11.selected.value &&
          e.value !== this.dd12.selected.value &&
          e.value !== this.dd15.selected.value &&
          e.value !== this.dd16.selected.value &&
          e.value !== this.dd13.selected.value
      );
      this.paramsDD15 = this.paramsFull.filter(
        (e) =>
          e.value !== this.dd11.selected.value &&
          e.value !== this.dd12.selected.value &&
          e.value !== this.dd14.selected.value &&
          e.value !== this.dd16.selected.value &&
          e.value !== this.dd13.selected.value
      );
      this.paramsDD16 = this.paramsFull.filter(
        (e) =>
          e.value !== this.dd11.selected.value &&
          e.value !== this.dd12.selected.value &&
          e.value !== this.dd15.selected.value &&
          e.value !== this.dd14.selected.value &&
          e.value !== this.dd13.selected.value
      );
    },
    changedd21() {
      this.saveDDLocal();
      const key = this.dd21.selected.value;
      const currObjM = this.currOverviewM;
      const prevObjM = this.prevOverviewM;
      const currObjF = this.currOverviewF;
      const prevObjF = this.prevOverviewF;

      let mp = 0;
      let mc = 0;
      let fp = 0;
      let fc = 0;

      if (key == 'localSpend' || key == 'avgCPT' || key == 'avgCPA') {
        mc = Number(currObjM[key].amount) || 0;
        mp = Number(prevObjM[key].amount) || 0;
        fc = Number(currObjF[key].amount) || 0;
        fp = Number(prevObjF[key].amount) || 0;
      } else {
        mc = currObjM[key] || 0;
        mp = prevObjM[key] || 0;
        fc = currObjF[key] || 0;
        fp = prevObjF[key] || 0;
      }
      let up = 0;
      let uc = 0;

      if (key == 'localSpend' || key == 'avgCPT' || key == 'avgCPA') {
        if (Number(this.prevOverview.grandTotal[key].amount) > mp + fp) up = Number(this.prevOverview.grandTotal[key].amount) - (mp + fp);
        if (Number(this.currOverview.grandTotal[key].amount) > mc + fc) uc = Number(this.currOverview.grandTotal[key].amount) - (mc + fc);
      } else {
        if (Number(this.prevOverview.grandTotal[key]) > mp + fp) up = this.prevOverview.grandTotal[key] - (mp + fp);
        if (Number(this.currOverview.grandTotal[key]) > mc + fc) uc = this.currOverview.grandTotal[key] - (mc + fc);
      }

      const gData = {
        mp: numberFormat(mp, true),
        mc: numberFormat(mc, true),
        fp: numberFormat(fp, true),
        fc: numberFormat(fc, true),
        up: numberFormat(up, true),
        uc: numberFormat(uc, true),
        t: this.dd21.selected.value,
        ts: Date.now(),
      };
      this.genData = gData;
      this.genderData = {
        data: gData,
        now: Date.now(),
      };
      this.saveLSS();
    },
    changedd22() {
      this.saveDDLocal();
      const key = this.dd22.selected.value;
      const currObjM = this.currOverviewPad;
      const prevObjM = this.prevOverviewPad;
      const currObjF = this.currOverviewPhone;
      const prevObjF = this.prevOverviewPhone;
      let mp = 0;
      let mc = 0;
      let fp = 0;
      let fc = 0;
      let up = 0;
      let uc = 0;

      if (key == 'localSpend' || key == 'avgCPT' || key == 'avgCPA') {
        mc = Number(currObjM[key].amount) || 0;
        mp = Number(prevObjM[key].amount) || 0;
        fc = Number(currObjF[key].amount) || 0;
        fp = Number(prevObjF[key].amount) || 0;
      } else {
        mc = currObjM[key] || 0;
        mp = prevObjM[key] || 0;
        fc = currObjF[key] || 0;
        fp = prevObjF[key] || 0;
      }
      if (key == 'localSpend' || key == 'avgCPT' || key == 'avgCPA') {
        if (Number(this.prevOverview.grandTotal[key].amount) > mp + fp) up = Number(this.prevOverview.grandTotal[key].amount) - (mp + fp);
        if (Number(this.currOverview.grandTotal[key].amount) > mc + fc) uc = Number(this.currOverview.grandTotal[key].amount) - (mc + fc);
      } else {
        if (Number(this.prevOverview.grandTotal[key]) > mp + fp) up = this.prevOverview.grandTotal[key] - (mp + fp);
        if (Number(this.currOverview.grandTotal[key]) > mc + fc) uc = this.currOverview.grandTotal[key] - (mc + fc);
      }

      const dData = {
        mp: numberFormat(mp, true) || 0,
        mc: numberFormat(mc, true) || 0,
        fp: numberFormat(fp, true) || 0,
        fc: numberFormat(fc, true) || 0,
        up: numberFormat(up, true) || 0,
        uc: numberFormat(uc, true) || 0,
        t: this.dd22.selected.value,
        ts: Date.now(),
      };
      this.deviceData = {
        data: dData,
        now: Date.now(),
      };
      this.saveLSS();
    },
    chartChanged(item) {
      if (this.currOverviewM && this.currOverviewF && item === 'Gender') {
        setTimeout(() => this.changedd21(), 500);
      }
    },
    changedd23() {
      this.saveDDLocal();
      const key = this.dd23.selected.value;
      if (this.mapData[key] && Object.keys(this.mapData[key]).length === 1) this.mapData[key]['XX'] = 0;
      Object.keys(this.mapData[key]).forEach((val, i) => {
        this.mapData[key][val] = parseFloat(this.mapData[key][val].toFixed(2));
      });
      this.locationData = this.mapData[key] ? this.mapData[key] : {};

      function formatNumber(value) {
        const roundedValue = parseFloat(value.toFixed(2));
        return Number.isInteger(roundedValue) ? roundedValue.toString() : roundedValue.toFixed(2);
      }
      this.saveLSS();
    },
    changedd31() {
      this.saveDDLocal();
      this.getTopKeywords();
      this.saveLSS();
    },
    changedd32() {
      this.saveDDLocal();
      this.getTopAdGroups();
      this.saveLSS();
    },
    showCheckList() {
      this.showCL = ~this.showCL;
      if (this.showCL) {
        window.scrollTo(0, 0);
      }
    },
    getOverview() {
      this.$apollo
        .query({
          query: APP_OVERVIEW,
          variables: {
            period: parseInt(this.period.value),
            gender: 'A',
            location: 'ALL',
            device: 'ALL',
            currdate: this.currentDate,
            prevdate: this.previousDate,
            appId: this.searchApp.adamId,
            change: 'none',
          },
        })
        .then((res) => {
          this.currOverviewM = res.data.currentOverview ? res.data.currentOverview.grandTotal.male : {};
          this.prevOverviewM = res.data.previousOverview ? res.data.previousOverview.grandTotal.male : {};
          this.currOverviewF = res.data.currentOverview ? res.data.currentOverview.grandTotal.female : {};
          this.prevOverviewF = res.data.previousOverview ? res.data.previousOverview.grandTotal.female : {};
          this.changedd21();
        })
        .catch((error) => {
        });
    },
    getOverviewDevice() {
      this.$apollo
        .query({
          query: APP_OVERVIEW_DEVICE,
          variables: {
            period: parseInt(this.period.value),
            gender: 'A',
            location: 'ALL',
            device: 'ALL',
            currdate: this.currentDate,
            prevdate: this.previousDate,
            appId: this.searchApp.adamId,
            change: 'none',
          },
        })
        .then((res) => {
          this.currOverviewPad = res.data.currentOverview ? res.data.currentOverview.grandTotal.ipad : {};
          this.prevOverviewPad = res.data.previousOverview ? res.data.previousOverview.grandTotal.ipad : {};
          this.currOverviewPhone = res.data.currentOverview ? res.data.currentOverview.grandTotal.iphone : {};
          this.prevOverviewPhone = res.data.previousOverview ? res.data.previousOverview.grandTotal.iphone : {};
          this.currOverviewUn = res.data.currentOverview ? res.data.currentOverview.grandTotal.unknown : {};
          this.prevOverviewUn = res.data.previousOverview ? res.data.previousOverview.grandTotal.unknown : {};
          this.changedd22();
        })
        .catch((error) => {
        });
    },
    getOverviewAlone() {
      if (!this.fullscreenLoaderAlone) this.fullscreenLoaderAlone = this.$loading.show();
      this.$apollo
        .query({
          query: APP_OVERVIEW_ALONE,
          variables: {
            period: parseInt(this.period.value),
            gender: 'A',
            location: 'ALL',
            device: 'ALL',
            currdate: this.currentDate,
            prevdate: this.previousDate,
            appId: this.searchApp.adamId,
            change: 'none',
          },
        })
        .then((res) => {
          this.currOverview = res.data.currentOverviewAlone;
          this.prevOverview = res.data.previousOverviewAlone;
          this.bindData('dd11', this.dd11.selected.value);
          this.bindData('dd12', this.dd12.selected.value);
          this.bindData('dd13', this.dd13.selected.value);
          this.bindData('dd14', this.dd14.selected.value);
          this.bindData('dd15', this.dd15.selected.value);
          this.bindData('dd16', this.dd16.selected.value);
          this.getOverview();
          this.getOverviewDevice();
          this.fullscreenLoaderAlone.hide();
        })
        .catch((error) => {
          this.fullscreenLoaderAlone.hide();
        });
    },
    getOverviewMap() {
      this.$apollo
        .query({
          query: APP_OVERVIEW_MAP,
          variables: {
            period: parseInt(this.period.value),
            gender: 'A',
            location: 'ALL',
            device: 'ALL',
            currdate: this.currentDate,
            prevdate: this.previousDate,
            appId: this.searchApp.adamId,
            change: 'none',
          },
        })
        .then((res) => {
          this.mapData = res.data.getMapOverview ? res.data.getMapOverview.grandTotal : {};
          this.changedd23();
        })
        .catch((error) => {
        });
    },
    bindData(from, key) {
      this.saveDDLocal();
      const comp = this[from];
      const currObj = this.currOverview.grandTotal;
      const prevObj = this.prevOverview.grandTotal;
      if (key == 'localSpend' || key == 'avgCPT' || key == 'avgCPA') {
        comp.displayCurr = true;
        comp.displayPercentage = false;
        comp.curr = currObj[key].currency;
        if (currObj.hasOwnProperty(key)) {
          comp.value = currObj[key].amount;
          if (parseFloat(currObj[key].amount) > parseFloat(prevObj[key].amount)) {
            comp.positive = true;
            comp.percent = 100 - Math.round((parseFloat(prevObj[key].amount) / parseFloat(currObj[key].amount)) * 100);
          } else if (parseFloat(currObj[key].amount) < parseFloat(prevObj[key].amount)) {
            comp.positive = false;
            comp.percent = 100 - Math.round((parseFloat(currObj[key].amount) / parseFloat(prevObj[key].amount)) * 100);
          }
          else {
            comp.positive = true;
            comp.percent = 0;
          }
        }
        return;
      }
      if (key == 'ttr' || key == 'conversionRate') {
        comp.displayCurr = false;
        comp.displayPercentage = true;
        if (currObj.hasOwnProperty(key)) {
          comp.value = currObj[key];
          if (currObj[key] > prevObj[key]) {
            comp.positive = true;
            comp.percent = 100 - Math.round((prevObj[key] / currObj[key]) * 100);
          }
          if (currObj[key] < prevObj[key]) {
            comp.positive = false;
            comp.percent = 100 - Math.round((currObj[key] / prevObj[key]) * 100);
          }
          if (currObj[key] == prevObj[key]) {
            comp.positive = true;
            comp.percent = 0;
          }
        }
        return;
      }
      if (currObj.hasOwnProperty(key)) {
        comp.displayCurr = false;
        comp.displayPercentage = false;
        comp.curr = '';
        comp.value = currObj[key];
        if (currObj[key] > prevObj[key]) {
          comp.positive = true;
          comp.percent = 100 - Math.round((prevObj[key] / currObj[key]) * 100);
        }
        if (currObj[key] < prevObj[key]) {
          comp.positive = false;
          comp.percent = 100 - Math.round((currObj[key] / prevObj[key]) * 100);
        }
        if (currObj[key] == prevObj[key]) {
          comp.positive = true;
          comp.percent = 0;
        }
      } else {
        comp.positive = true;
        comp.percent = 0;
        comp.displayCurr = false;
        comp.displayPercentage = false;
        if (this.mmpKeys.includes(key)) {
          let c, p;
          switch (key) {
            case 'GETC': // count of all the postbacks received by kochava for all the events in a goal
              comp.value = this.gecCurr;
              this.allCases(comp, this.gecCurr, this.gecPrev);
              break;
            case 'CPRG': // ad spend from ASA divided by GETC
              comp.displayCurr = true;
              c = parseInt(currObj['localSpend'].amount) / this.gecCurr || 0;
              p = parseInt(prevObj['localSpend'].amount) / this.gecPrev || 0;
              if (this.gecCurr == 0) c = 0;
              if (this.gecPrev == 0) p = 0;
              comp.value = c;
              this.allCases(comp, c, p);
              break;
            case 'RPRG': // goal revenue divided by goal event count
              comp.displayCurr = true;
              c = this.goalRevenueCurr / this.gecCurr || 0;
              p = this.goalRevenuePrev / this.gecPrev || 0;
              if (this.gecCurr == 0) c = 0;
              if (this.gecPrev == 0) p = 0;
              comp.value = c;
              this.allCases(comp, c, p);
              break;
            case 'GREV': // the total revenue for this app received from kochava(in this.revenueData)
              comp.displayCurr = true;
              comp.value = this.goalRevenueCurr;
              this.allCases(comp, this.goalRevenueCurr, this.goalRevenuePrev);
              break;
            case 'GRAS': // GREV divided by ad spend from ASA
              comp.displayCurr = false;
              c = this.goalRevenueCurr / parseInt(currObj['localSpend'].amount) || 0;
              p = this.goalRevenuePrev / parseInt(prevObj['localSpend'].amount) || 0;
              if (parseInt(currObj['localSpend'].amount) == 0) c = 0;
              if (parseInt(prevObj['localSpend'].amount) == 0) p = 0;
              comp.value = c;
              this.allCases(comp, c, p);
              break;
            case 'ARPU': // GREV minus divided by installs
              comp.displayCurr = true;
              c = this.goalRevenueCurr / parseInt(currObj['installs']) || 0;
              p = this.goalRevenuePrev / parseInt(prevObj['installs']) || 0;
              if (parseInt(currObj['installs']) == 0) c = 0;
              if (parseInt(prevObj['installs']) == 0) p = 0;
              comp.value = c;
              this.allCases(comp, c, p);
              break;
            case 'AMPU': // GREV minus ad spend div by installs from ASA
              comp.displayCurr = true;
              c = (this.goalRevenueCurr - parseInt(currObj['localSpend'].amount)) / parseInt(currObj['installs']) || 0;
              p = (this.goalRevenuePrev - parseInt(prevObj['localSpend'].amount)) / parseInt(prevObj['installs']) || 0;
              if (parseInt(currObj['installs']) == 0) c = 0;
              if (parseInt(prevObj['installs']) == 0) p = 0;
              comp.value = c;
              this.allCases(comp, c, p);
              break;
            default:
              comp.value = 0;
              break;
          }
        } else if (this.asaKeys.includes(key)) {
          comp.value = 0;
        } else {
          const eventsData = this.eventsData.filter((e) => e.event_name.toLowerCase() == key.toLowerCase());
          comp.value = eventsData[0].count;
        }
      }
    },
    allCases(comp, a, b) {
      if (a > b) {
        comp.positive = true;
        comp.percent = 100 - Math.round((b / a) * 100);
        if (a == 0) {
          comp.percent = 0;
        }
      }
      if (a < b) {
        comp.positive = false;
        comp.percent = 100 - Math.round((a / b) * 100);
        if (b == 0) {
          comp.percent = 0;
        }
      }
      if (a == b) {
        comp.positive = true;
        comp.percent = 0;
      }
    },
    saveDDLocal() {
      LSS.overviewDD = {
        dd11: this.dd11.selected,
        dd12: this.dd12.selected,
        dd13: this.dd13.selected,
        dd14: this.dd14.selected,
        dd15: this.dd15.selected,
        dd16: this.dd16.selected,
        dd21: this.dd21.selected,
        dd22: this.dd22.selected,
        dd23: this.dd23.selected,
        dd31: this.dd31.selected,
        dd32: this.dd32.selected,
      };
    },
    getTopAdGroups() {
      this.$apollo
        .query({
          query: TOP_ADS,
          variables: {
            appId: this.searchApp.adamId,
            param: this.dd32.selected.value,
            period: this.period.value,
            goalId: this.selectedGoal._id,
          },
        })
        .then((res) => {
          this.topAdgroups = res.data.getTopAds;
        })
        .catch((error) => {
        });
    },
    getTopKeywords() {
      this.$apollo
        .query({
          query: TOP_KEYS,
          variables: {
            appId: this.searchApp.adamId,
            param: this.dd31.selected.value,
            period: this.period.value,
            goalId: this.selectedGoal._id,
          },
        })
        .then((res) => {
          this.topKeywords = res.data.getTopKeys;
        })
        .catch((error) => {
        });
    },
    goToDashBoard(tab) {
      this.$router.push({ path: '/ads-manager', query: { tab: tab } });
    },
    updateCheckList() {
      if (!LSS.token || !decodeToken(LSS.token).checkList) {
        this.existCL = false;
        return;
      } else {
        this.existCL = true;
      }
      if (LSS.token && decodeToken(LSS.token).checkList) {
        const checkList = decodeToken(LSS.token).checkList;
        if (checkList.createCampaign && checkList.adsManager && !checkList.overviewPage) {
          updateCLF('overviewPage', this);
        }
        if (checkList.saIntegration && checkList.slackIntegration && checkList.mmpIntegration) {
          this.check0 = 'mdi-check-circle';
          this.color0 = 'primary';
        }
        if (checkList.saIntegration) {
          this.check1 = 'mdi-check-circle';
          this.color1 = 'primary';
        }
        if (checkList.kaiComp) {
          this.check2 = 'mdi-check-circle';
          this.color2 = 'primary';
        }
        if (checkList.kaiRecom) {
          this.check7 = 'mdi-check-circle';
          this.color7 = 'primary';
        }
        if (checkList.kaiApp) {
          this.check8 = 'mdi-check-circle';
          this.color8 = 'primary';
        }
        if (checkList.kaiKeyword) {
          this.check9 = 'mdi-check-circle';
          this.color9 = 'primary';
        }
        if (checkList.kaiTop) {
          this.check10 = 'mdi-check-circle';
          this.color10 = 'primary';
        }
        if (checkList.kaiComp && checkList.kaiApp && checkList.kaiKeyword && checkList.kaiTop) {
          this.check11 = 'mdi-check-circle';
          this.color11 = 'primary';
        }
        if (checkList.createCampaign) {
          this.check16 = 'mdi-check-circle';
          this.color16 = 'primary';
        }
        if (checkList.automationRules && checkList.createRule) {
          this.check4 = 'mdi-check-circle';
          this.color4 = 'primary';
        }
        if (checkList.slackIntegration) {
          this.check6 = 'mdi-check-circle';
          this.color6 = 'primary';
        }
        if (checkList.createCampaign) {
          this.check12 = 'mdi-check-circle';
          this.color12 = 'primary';
        }
        if (checkList.createAdgroup || checkList.createCampaign) {
          this.check13 = 'mdi-check-circle';
          this.color13 = 'primary';
        }
        if (checkList.addKeys || checkList.createCampaign) {
          this.check14 = 'mdi-check-circle';
          this.color14 = 'primary';
        }
        if (checkList.addCS) {
          this.check15 = 'mdi-check-circle';
          this.color15 = 'primary';
        }
        if (checkList.createCampaign && checkList.adsManager) {
          this.check3 = 'mdi-check-circle';
          this.color3 = 'primary';
        }
        if (checkList.createCampaign && checkList.adsManager) {
          this.check5 = 'mdi-check-circle';
          this.color5 = 'primary';
        }
        if (checkList.mmpIntegration) {
          this.mmpIntCheck = 'mdi-check-circle';
          this.mmpIntColor = 'primary';
        }
        if (checkList.createMmpGoals) {
          this.createMmpGoalsCheck = 'mdi-check-circle';
          this.createMmpGoalsColor = 'primary';
        }
        if (checkList.scheduledReports) {
          this.scheduledReportsCheck = 'mdi-check-circle';
          this.scheduledReportsColor = 'primary';
        }
        if (checkList.campGen) {
          this.campGenCheck = 'mdi-check-circle';
          this.campGenColor = 'primary';
        }
      }
    },
    async init() {
      this.loadingApps = true;
      if (!LSS.ownApps) LSS.ownApps = [];
      if (LSS.ownApps && LSS.ownApps.length > 0) {
        this.populateApps();
      } else {
        LSS.ownApps = [];
        if (LSS.asaInt) {
          await getOwnApps('overview', this);
        } else {
          this.loadingApps = false;
          this.populateApps();
        }
      }
      this.getOverviewAlone();
      this.getOverviewMap();
      this.getTopAdGroups();
      this.getTopKeywords();
      this.updateCheckList();
      this.getGoals();
      this.paramsDD11 = this.paramsFull.filter(
        (e) => e.value !== 'installs' && e.value !== 'taps' && e.value !== 'avgCPA' && e.value !== 'avgCPT' && e.value !== 'localSpend'
      );
      this.paramsDD13 = this.paramsFull.filter(
        (e) => e.value !== 'impressions' && e.value !== 'taps' && e.value !== 'avgCPA' && e.value !== 'avgCPT' && e.value !== 'localSpend'
      );
      this.paramsDD12 = this.paramsFull.filter(
        (e) =>
          e.value !== 'impressions' && e.value !== 'installs' && e.value !== 'avgCPA' && e.value !== 'avgCPT' && e.value !== 'localSpend'
      );
      this.paramsDD14 = this.paramsFull.filter(
        (e) => e.value !== 'installs' && e.value !== 'taps' && e.value !== 'avgCPA' && e.value !== 'avgCPT' && e.value !== 'impressions'
      );
      this.paramsDD15 = this.paramsFull.filter(
        (e) => e.value !== 'installs' && e.value !== 'taps' && e.value !== 'avgCPT' && e.value !== 'localSpend' && e.value !== 'impressions'
      );
      this.paramsDD16 = this.paramsFull.filter(
        (e) => e.value !== 'installs' && e.value !== 'taps' && e.value !== 'localSpend' && e.value !== 'avgCPA' && e.value !== 'impressions'
      );

      await getOwnApps('overview', this);
      if (this.searchedApps.length === 2) {
        this.changeApp(this.searchedApps[1]);
      }
    },
    convertToShortCurr(curr) {
      return Intl.NumberFormat('en-US', {
        notation: 'compact',
      }).format(numberFormat(curr, true));
    },
    numFormat(curr) {
      return numberFormat(curr);
    },
    saveLSS() {
      const overviewdd = {
        dd11: this.dd11.selected,
        dd12: this.dd12.selected,
        dd13: this.dd13.selected,
        dd14: this.dd14.selected,
        dd15: this.dd15.selected,
        dd16: this.dd16.selected,
        dd21: this.dd21.selected,
        dd22: this.dd22.selected,
        dd23: this.dd23.selected,
        dd31: this.dd31.selected,
        dd32: this.dd32.selected,
      };
      this.$apollo.mutate({
        mutation: SAVE_OVERVIEW_PRESETS,
        variables: {
          tabInfo: 'overview',
          presets: JSON.stringify(overviewdd),
        },
      });
    },
    populateApps() {
      this.searchedApps = LSS.ownApps; // takes no extra memory since they bot point to same object(array)
      const attrExistAll = this.searchedApps.filter((e) => e.attrExist);
      this.searchedApps.unshift({
        adamId: 0,
        appName: this.searchedApps.length > 0 ? 'All' : 'No Apps',
        appIcon: 'mdi-cellphone',
        attrExist: this.searchedApps.length == attrExistAll.length ? true : false,
      });
      if (this.searchedApps.length > 2) {
        this.searchApp = {
          adamId: 0,
          appName: this.searchedApps.length > 0 ? 'All' : 'No Apps',
          attrExist: this.searchedApps.length == attrExistAll.length ? true : false,
          appIcon: '',
        };
      } else {
        this.searchApp = this.searchedApps[1];
      }
      this.loadingApps = false;
    },
    matchItem(string, data) {
      var i = 0,
        j = 0,
        html = '',
        regex,
        regexv,
        match,
        matches,
        version;

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, 'i');
        match = regex.test(string);
        if (match) {
          regexv = new RegExp(data[i].version + '[- /:;]([\d._]+)', 'i');
          matches = string.match(regexv);
          version = '';
          if (matches) {
            if (matches[1]) {
              matches = matches[1];
            }
          }
          if (matches) {
            matches = matches.split(/[._]+/);
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + '.';
              } else {
                version += matches[j];
              }
            }
          } else {
            version = '0';
          }
          return {
            name: data[i].name,
            version: parseFloat(version),
          };
        }
      }
      return { name: 'unknown', version: 0 };
    },
    isLoginActivitySaved() {
      if (!LSS.loginActivity) return false;
      if (LSS.loginActivity) {
        const loginActivity = LSS.loginActivity;
        if (loginActivity.includes(decodeToken(LSS.token).TeamID)) return true;
        else return false;
      }
    },
    saveLoginActivity() {
      if (!this.isLoginActivitySaved()) {
        const agent = this.browserHeader.join(' ');
        const browser = this.matchItem(agent, this.databrowser);
        this.$apollo
          .mutate({
            mutation: SAVE_LOGIN_ACTIVITY,
            variables: {
              browser: browser.name,
            },
          })
          .then((res) => {
            if (LSS.loginActivity) {
              const loginAc = [...LSS.loginActivity];
              loginAc.push(decodeToken(LSS.token).TeamID);
              LSS.loginActivity = loginAc;
            } else {
              const loginAc = [];
              loginAc.push(decodeToken(LSS.token).TeamID);
              LSS.loginActivity = loginAc;
            }
          });
      }
    },
  },
  watch: {
    genData: function (gdata) {
    },
    searchApp: function (d) {
    },
    selectedDatasets(data) {
      if (data.length == this.reportDatasets.length) {
        this.selectAllIcon = 'mdi-checkbox-multiple-marked';
      } else {
        this.selectAllIcon = 'mdi-crop-square';
      }
    },
    filterDateRange(data) {
      LSS.dateRange = data;
    },
  },
  mounted() {
    this.$root.$on('new-ds-progress', (p) => {
      this.drProgress = p;
    });
    this.saveLoginActivity();
    this.syncStatus = LSS.activeSync;
    Vue.prototype.$sckt.on('syncprogress:event', () => {
      this.syncStatus = LSS.activeSync;
    });
    this.locale = localStorage.getItem('locale') || 'en';
    this.$root.$emit('overviewOpen');
    this.init();
    this.$root.$on('userNameFetched', (userDet) => {
      this.updateCheckList();
    });
    this.$root.$on('locale-changed', (locale) => {
      this.locale = locale.localeCode;
      this.repopulateDDNew();
      this.changedd21();
      this.changedd22();
    });
    this.$root.$on('org-changed', (l) => {
      if (this.$route.name == 'overview') this.init();
    });
    this.$root.$on('apps-refreshed', (data) => {
      this.loadingApps = false;
      this.populateApps();
    });
    if (!LSS.attr) LSS.attr = false;
    if (LSS.attr) {
      LSS.overviewDD = {
        dd11: {
          text: 'Impressions',
          value: 'impressions',
        },
        dd12: {
          text: 'Taps',
          value: 'taps',
        },
        dd13: {
          text: 'Installs',
          value: 'installs',
        },
        dd14: {
          text: 'Spend',
          value: 'localSpend',
        },
        dd15: {
          text: 'Average CPA',
          value: 'avgCPA',
        },
        dd16: {
          text: 'Average CPT',
          value: 'avgCPT',
        },
        dd21: {
          text: 'Impressions',
          value: 'impressions',
        },
        dd22: {
          text: 'Impressions',
          value: 'impressions',
        },
        dd23: {
          text: 'Impressions',
          value: 'impressions',
        },
        dd31: {
          text: 'Spend',
          value: 'localSpend',
        },
        dd32: {
          text: 'Spend',
          value: 'localSpend',
        },
      };
      LSS.attr = false;
    }
    if (LSS?.overviewDD?.dd11) {
      const data = LSS.overviewDD;
      this.dd11.selected = data.dd11;
      this.dd12.selected = data.dd12;
      this.dd13.selected = data.dd13;
      this.dd14.selected = data.dd14;
      this.dd15.selected = data.dd15;
      this.dd16.selected = data.dd16;
      this.dd21.selected = data.dd21;
      this.dd22.selected = data.dd22;
      this.dd23.selected = data.dd23;
      this.dd31.selected = data.dd31;
      this.dd32.selected = data.dd32;
      this.repopulateDDNew();
    }
    if (!LSS.kaiQuery) {
      LSS.kaiQuery = {
        tab: 0,
        appId: 0,
        title: '',
        location: 'us',
        language: 'us',
        device: 'iphone',
        icon: '',
        keyword: '',
        CountPref: 10,
      };
    }
    if (!LSS.drsc) LSS.drsc = 0;
    if (!LSS.drctd) LSS.drctd = 6014;
    if (!LSS.drctt) LSS.drctt = 'free';
    if (!LSS.drsba) LSS.drsba = 0;
    if (!LSS.drsbk) LSS.drsbk = null;
  },
  beforeDestroy() {
    this.searchedApps = null; //for GC
  },
});
</script>

<style scoped>
.displayFlex {
  display: flex !important;
}
.v-list-item a {
  color: rgba(64, 64, 66, 0.6);
  line-height: 1.375rem;
}
.colFullOverview .col-right-main .titleText a {
  color: rgba(0, 0, 0, 0.6);
}
.colFullOverview .col-right-main .v-list-item__content i.v-icon {
  padding-top: 5px;
}

::v-deep .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value {
  color: #212121 !important;
}

::v-deep .svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title {
  color: #b0b0b0 !important;
}
.svg-funnel-js {
  width: 100%;
  height: 100%;
}
</style>
